const Menu = [
  {
    path: '/', icon: 'fa fa-home', title: 'Home'
  },
  {
    path: '#', icon: 'fa fa-cube', title: 'Receiving',
    children: [
      { path: '/receiving', title: 'Receiving', icon: "fa fa-cubes" }, 
      {
        path: '#', title: 'Reporting',
        children: [
          { path: '/master/province', title: 'Bagging Report' },

        ]
      },
    ]
  },
  {
    path: '#', icon: 'fa fa-file-alt', title: 'Shipment',
    children: [
      { path: '/shipment/create', icon: 'fa fa-cubes', title: 'Create Shipment', },
      { path: '/shipment', icon: 'fa fa-cubes', title: 'Shipment List', },
      { path: '/shipment/bulky-upload', icon: 'fa fa-upload', title: 'Bulky Upload', }
    ]
  },
  // {
  //   path: '/receiving', icon: 'fa fa-th', title: 'Receiving'
  // },
  // {
  //   path: '/bagging', icon: 'fa fa-cubes', title: 'Bagging'
  // },
  {
    path: '#', icon: 'fa fa-cubes', title: 'Bagging',
    children: [
      { path: '/bagging', title: 'Bagging', icon: "fa fa-cubes" },
      { path: '/unbagging', title: 'Un Bagging', icon: "fa fa-cube" },
      {
        path: '#', title: 'Reporting',
        children: [
          { path: '/master/province', title: 'Bagging Report' },

        ]
      },
    ]
  },
  {
    path: '#', icon: 'fa fa-truck', title: 'Manifest',
    children: [
      { path: '/manifest', title: 'Manifest List' },
      { path: '/manifest/dispatch', title: 'Manifest Dispatch' },
      { path: '/manifest/incoming', title: 'Incoming Manifest' },
      {
        path: '#', title: 'Reporting',
        children: [
          { path: '/master/province', title: 'Manifest Report' },
          { path: '#', title: 'Manifest In Report' },
          { path: '#', title: 'Manifest Out Report' }, 
        ]
      },
    ]
  },
  {
    path: '#', icon: 'fa fa-motorcycle', title: 'Last Miles',
    children: [
      { path: '/runsheet', icon: "fa fa-motorcycle" , title: 'Run Sheet List' }, 
      { path: '/runsheet/pod', title: 'POD Runsheet' },
      {
        path: '#', title: 'Reporting',
        children: [
          { path: '/master/province', title: 'Manifest Report' },
          { path: '#', title: 'Manifest In Report' },
          { path: '#', title: 'Manifest Out Report' },

        ]
      },
    ]
  },
  {
    path: '/menu', icon: 'fa fa-cogs', title: 'Master Data',
    children: [

      {
        path: '#', title: 'Area', icon: 'fa fa-map',
        children: [
          { path: '/master/province', icon: 'fa fa-map-marker-alt', title: 'Province' },
          { path: '/master/city', icon: 'fa fa-map-marker-alt', title: 'City' },
          { path: '/master/district', icon: 'fa fa-map-marker-alt', title: 'District' },
          { path: '#', icon: 'fa fa-map-marker-alt', title: 'Sub District' },
          { path: '#', icon: 'fa fa-map-marker-alt', title: 'Customer Area Mapping' },
        ]
      },
      {
        path: '#', title: 'Courier',
        children: [
          { path: '/master/courier', title: 'Courier List' },
        ]
      },
      {
        path: '/master/user', title: 'User', icon: "fa fa-users"
      },
      {
        path: '/master/customer', title: 'Customer & Account',
        children: [
          { path: '/master/customer', icon: 'fa fa-address-book', title: 'Customer List' },
          { path: '/master/account', icon: 'fa fa-address-book', title: 'Account List' },
        ]
      },
      { path: '/master/service-type', title: 'Service Type' },
      { path: '/master/package-type', title: 'Package Type', icon: "fa fa-tags" },
    ]
  }
]

export default Menu;
