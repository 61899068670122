import React from "react";
import { Link, useParams } from 'react-router-dom';
import axios from 'axios'
import { Panel, PanelBody, PanelHeader, PanelFooter } from '../../components/panel/panel.jsx';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable'
import { PageSettings } from '../../config/page-settings.js';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import 'react-tabs/style/react-tabs.css';
import moment from 'moment/moment.js';
import { NumericFormat } from 'react-number-format'; 
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Switch from "react-switch";
import { isEmpty, numericValue } from "../../helper/helper.js"

function withParams(Component) {
	return props => <Component {...props} params={useParams()} />;
}

class Shipment extends React.Component {
	state = {
		provinces: [],
		citiesShipper: [],
		districtsShipper: [],
		subdistrictsShipper: [],
		postalcodesShipper: [],
		citiesConsignee: [],
		mDistrictConsignee: [],
		mSubDistrictConsignee: [],
		postalcodesConsignee: [],
		mCouriers: [],
		mServiceType: [],
		mPackageType: [],
		statuses: [],
		shippers: [],
		account: null,
		shipmentQtyCollies: 1,
		packageLength: 10,
		packageWidth: 10,
		packageHeight: 10,
		packageWeight: 1,
		packageValue: 0,
		shipmentDate: moment().format("YYYY-MM-DD"),
		shipmentNum: null,
		consigneeDistrict: null,
		shipmentStatus: 0,

	}

	static contextType = PageSettings;

	_handleOnChangeInputText = async (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}

	_handleOnChangeSwitch = async (c, e, id) => {
		this.setState({ [id]: c })
	}

	onChangeShipperCode = async (option, e) => {
		if (e.action === "select-option") { 

			await axios.post(`${window.APP_API}/api/v1/account/view-by-code`,
				{ acc_code: option.value })
				.then(res => {

					this.setState({
						customerCode: res.data.custCode,
						accountCode: res.data.accCode,
						shipperCode: res.data.accCode,
						shipperName: res.data.accName,
						shipperAddress: res.data.address,
						shipperProvince: res.data.provinceCode,
						shipperProvinceName: res.data.provinceName,
						shipperCity: res.data.cityCode,
						shipperCityName: res.data.cityName,
						shipperDistrict: res.data.districtCode,
						shipperDistrictName: res.data.districtName,
						shipperSubDistrict: res.data.subDistrictCode,
						shipperSubDistrictName: res.data.subDistrictName,
						shipperPostalCode: res.data.postalCode,
						shipperPhone: res.data.phone,
						shipperEmail: res.data.email
					})

					this.onChangeShipperProvince({ value: res.data.provinceCode, label: res.data.provinceName }, { action: "select-option" })
					this.onChangeShipperCity({ value: res.data.cityCode, label: res.data.cityName }, { action: "select-option" })
					this.onChangeShipperDistrict({ value: res.data.districtCode, label: res.data.districtName }, { action: "select-option" })
					this.onChangeShipperSubDistrict({ value: res.data.subDistrictCode, label: res.data.subDistrictName }, { action: "select-option" })
					this.onChangeShipperPostcode({ value: res.data.postalCode, label: res.data.postalCode }, { action: "select-option" })

				}).catch(err => {
				})
		}
	}

	onChangeShipperProvince = async (option, e) => {
		if (e.action === "select-option") { 
			this.setState({ shipperProvince: option.value, shipperProvinceName: option.label })

			await axios.post(`${window.APP_API}/api/v1/ui/city`, {
				prov_code: option.value
			}).then(res => {
				const cities = res.data;
				this.setState({ citiesShipper: cities })
			}).catch(err => {

			})
		} else if (e.action === "clear") {
			this.setState({
				shipperProvince: null,
				shipperProvinceName: null,
				shipperCity: null,
				shipperCityName: null,
				shipperDistrict: null,
				shipperDistrictName: null,
				shipperSubDistrict: null,
				shipperSubDistrictName: null,
				shipperPostalCode: null,
				citiesShipper: [],
				districtsShipper: [],
				subdistrictsShipper: [],
				postalcodesShipper: [],
			})
		}
	}

	onChangeShipperCity = async (option, e) => {
		if (e.action === "select-option") {

			this.setState({ shipperCity: option.value, shipperCityName: option.label })

			await axios.post(`${window.APP_API}/api/v1/ui/district`, {
				city_code: option.value
			}).then(res => {
				const districts = res.data;
				this.setState({ districtsShipper: districts })
			}).catch(err => {

			})
		} else if (e.action === "clear") {
			this.setState({
				shipperCity: null,
				shipperCityName: null,
				shipperDistrict: null,
				shipperDistrictName: null,
				shipperSubDistrict: null,
				shipperSubDistrictName: null,
				shipperPostalCode: null,
				districtsShipper: [],
				subdistrictsShipper: [],
				postalcodesShipper: [],
			})

		}
	}

	onChangeShipperDistrict = async (option, e) => {
		if (e.action === "select-option") {
			this.setState({ shipperDistrict: option.value, shipperDistrictName: option.label })

			await axios.post(`${window.APP_API}/api/v1/ui/subdistrict`, {
				district_code: option.value
			}).then(async (res) => {
				const subdistricts = res.data;
				this.setState({ subdistrictsShipper: subdistricts })
			}).catch(err => {
			})

			await axios.post(`${window.APP_API}/api/v1/ui/postalcode`, {
				district_code: option.value
			}).then(async (res) => {
				const postalcodes = res.data;
				this.setState({ postalcodesShipper: postalcodes })
			}).catch(err => {

			})

		} else if (e.action === "clear") {
			this.setState({
				shipperDistrict: null,
				shipperDistrictName: null,
				shipperSubDistrict: null,
				shipperSubDistrictName: null,
				shipperPostalCode: null,
				subdistrictsShipper: [],
				postalcodesShipper: [],
			})
		}
	}

	onChangeShipperSubDistrict = (option, e) => {
		if (e.action === "select-option") {
			this.setState({ shipperSubDistrict: option.value, shipperSubDistrictName: option.label })

		} else if (e.action === "clear") {
			this.setState({
				shipperSubDistrict: null,
				shipperSubDistrictName: null,
				shipperPostalCode: null
			})
		}
	}

	onChangeShipperPostcode = async (option, e) => {
		if (e.action === "select-option") {
			this.setState({ shipperPostalCode: option.value })
		} else if (e.action === "clear") {
			this.setState({ shipperPostalCode: null })
		}
	}


	onChangeConsigneeProvince = async (option, e) => {
		if (e.action === "select-option") {
			this.setState({ consigneeProvince: option.value, consigneeProvinceName: option.label })

			await axios.post(`${window.APP_API}/api/v1/ui/city`, {
				prov_code: option.value
			}).then(res => {
				const cities = res.data;
				this.setState({ citiesConsignee: cities })
			}).catch(err => {

			})
		} else if (e.action === "clear") {
			this.setState({
				consigneeProvince: null,
				consigneeProvinceName: null,
				consigneeCity: null,
				consigneeCityName: null,
				consigneeDistrict: null,
				consigneeDistrictName: null,
				consigneeSubDistrict: null,
				consigneeSubDistrictName: null,
				consigneePostalCode: null,
				citiesConsignee: [],
				mDistrictConsignee: [],
				mSubDistrictConsignee: [],
				postalcodesConsignee: []
			})
		}
	}

	onChangeConsigneeCity = async (option, e) => {
		if (e.action === "select-option") {
			await axios.post(`${window.APP_API}/api/v1/ui/district`, {
				city_code: option.value
			}).then(res => {
				const districts = res.data;
				this.setState({ consigneeCity: option.value, consigneeCityName: option.label, mDistrictConsignee: districts })
			}).catch(err => {
			})

		} else if (e.action === "clear") {
			this.setState({
				consigneeCity: null,
				consigneeCityName: null,
				consigneeDistrict: null,
				consigneeDistrictName: null,
				consigneeSubDistrict: null,
				consigneeSubDistrictName: null,
				consigneePostalCode: null,
				mDistrictConsignee: [],
				mSubDistrictConsignee: [],
				postalcodesConsignee: []
			})

		}
	}

	onChangeConsigneeDistrict = async (option, e) => {
		if (e.action === "select-option") {
			await axios.post(`${window.APP_API}/api/v1/ui/subdistrict`, {
				district_code: option.value
			}).then(async (res) => {
				this.setState({
					consigneeDistrict: option.value,
					consigneeDistrictName: option.label,
					mSubDistrictConsignee: res.data
				})

			}).catch(err => {
			})

			await axios.post(`${window.APP_API}/api/v1/ui/postalcode`, {
				district_code: option.value
			}).then(async (res) => {
				const postalcodes = res.data;
				this.setState({ postalcodesConsignee: postalcodes })
			}).catch(err => {
			})

		} else if (e.action === "clear") {
			this.setState({
				consigneeDistrict: null,
				consigneeDistrictName: null,
				consigneeSubDistrict: null,
				consigneeSubDistrictName: null,
				consigneePostalCode: null,
				mSubDistrictConsignee: [],
				postalcodesConsignee: []
			})

		}
	}

	onChangeConsigneeSubDistrict = (option, e) => {
		if (e.action === "select-option") {
			this.setState({ consigneeSubDistrict: option.value, consigneeSubDistrictName: option.label })

		} else if (e.action === "clear") {
			this.setState({
				consigneeSubDistrict: null,
				consigneeSubDistrictName: null,
				consigneePostalCode: null
			})
		}
	}

	onChangeConsigneePostcode = async (option, e) => {
		if (e.action === "select-option") {
			this.setState({ consigneePostalCode: option.value })
		} else if (e.action === "clear") {
			this.setState({ consigneePostalCode: null })
		}
	}


	save = async (e) => {

		if (isEmpty(this.state.shipmentNum)) {
			NotificationManager.warning(`Shipment Number is not allow Empty`); return;
		}

		if (isEmpty(this.state.shipperCode)) {
			await NotificationManager.warning("Shipper is not allow emptyx");
			alert(this.state.shipperCode)
			return;
		}

		const params = {
			uuid: this.state.uuid ?? null,
			shipmentNumber: this.state.shipmentNum,
			referenceNumber: this.state.referenceNum,
			shipmentDate: this.state.shipmentDate,
			courierCode: this.state.courierCode,
			serviceType: this.state.serviceType ?? null,
			requestPickupDate: this.state.shipmentDate,
			customerCode: this.state.customerCode,
			accountCode: this.state.accountCode,
		}

		params.shipper = {
			name: this.state.shipperName,
			address: this.state.shipperAddress,
			province: this.state.shipperProvince,
			provinceName: this.state.shipperProvinceName,
			city: this.state.shipperCity,
			cityName: this.state.shipperCityName,
			district: this.state.shipperDistrict,
			districtName: this.state.shipperDistrictName,
			subDistrict: this.state.shipperSubDistrict,
			subDistrictName: this.state.shipperSubDistrictName,
			postalCode: this.state.shipperPostalCode,
			email: this.state.shipperEmail,
			phone: this.state.shipperPhone,
		}
		params.receiver = {
			name: this.state.consigneeName,
			address: this.state.consigneeAddress,
			province: this.state.consigneeProvince,
			provinceName: this.state.consigneeProvinceName,
			city: this.state.consigneeCity,
			cityName: this.state.consigneeCityName,
			district: this.state.consigneeDistrict,
			districtName: this.state.consigneeDistrictName,
			subDistrict: this.state.consigneeSubDistrict,
			subDistrictName: this.state.consigneeSubDistrictName,
			postalCode: this.state.consigneePostalCode,
			email: this.state.consigneeEmail,
			phone: this.state.consigneePhone,
		}
		params.packageInfo = {
			type: this.state.packageType,
			descr: this.state.packageDescr,
			qty: numericValue(this.state.shipmentQtyCollies),
			weight: numericValue(this.state.packageWeight),
			value: numericValue(this.state.packageValue),
			dimension: {
				length: numericValue(this.state.packageLength),
				width: numericValue(this.state.packageWidth),
				height: numericValue(this.state.packageHeight)
			}
		}

		params.cod = this.state.isCod;
		params.insurance = this.state.isInsurance;
		params.repacking = this.state.isRepacking;

		params.specialInstruction = this.state.specialInstruction
		let url = `${window.APP_API}/api/v1/shipment/new`
		if (this.state.uuid !== undefined) {
			url = `${window.APP_API}/api/v1/shipment/${this.state.uuid}/update`
		}

		await axios.post(url,
			params
		)
			.then(async (res) => {
				this.setState({ waybillNum: res.data.waybillNum })
				await NotificationManager.success(res.data.message);

				if (this.state.uuid === undefined) {
					setTimeout(() => {
						window.location.replace(`/shipment/${res.data.uuid}/view`);
					}, 2000)
				}

			}).catch(async (err) => {
				await NotificationManager.error(`Failed  ${err.response.data.message}`);
			})
	}

	confirm = async (e) => {
		if (isEmpty(this.state.shipperCode)) {
			await NotificationManager.warning("Shipper is not allow empty");
			return;
		}
		if (isEmpty(this.state.courierCode)) {
			await NotificationManager.warning("Courier is not allow empty");
			return;
		}


		const params = {}
		await axios.post(`${window.APP_API}/api/v1/shipment/${this.state.uuid}/confirm`, {
			params
		}).then(async (res) => {
			await NotificationManager.success(res.data.message);
			setTimeout(() => {
				window.location.replace(`/shipment/${this.state.uuid}/view`);
			}, 2000)

		}).catch(async (err) => { 
			await NotificationManager.error(err.response.data.message)
		})
	}

	printlabel = () => {

	}

	calculateVolume = () => {
		return ((numericValue(this.state.packageLength) * numericValue(this.state.packageWidth) * numericValue(this.state.packageHeight)) / 1000000)
	}
	async componentDidMount() {
		this.context.handleSetPageSidebarMinified(true);
		this.context.handleSetPageFooter(false);

		await axios.get(`${window.APP_API}/api/v1/ui/fshipment`)
			.then(res => {
				const data = res.data;
				this.setState({
					provinces: data.provinces ?? [],
					mCouriers: data.couriers ?? [],
					mServiceType: data.serviceTypes ?? [],
					mPackageType: data.packageTypes ?? [],
					statuses: data.statuses ?? [],
					shippers: data.accounts ?? [],
				})
			}).catch(err => {

			})

		if (this.props.params.uuid) {
			this.setState({ uuid: this.props.params.uuid })

			await axios.post(`${window.APP_API}/api/v1/shipment/${this.props.params.uuid}/view`)
				.then(async (res) => { 

					const pt = this.state.mPackageType.find(el => el.value === res.data.packageType)
					if(!isEmpty(res.data.packageType) && pt=== undefined)  { 
						this.setState(prevState => ({
							mPackageType: [{label: `** ${res.data.packageType}`, value: res.data.packageType}, ...prevState.mPackageType],
						}))
					}

					this.setState({
						shipmentNum: res.data.shipmentNum,
						shipmentStatus: res.data.shipmentStatus,
						waybillNum: res.data.waybillNum,
						extWaybillNum: res.data.extWaybillNum,
						serviceType: res.data.serviceType,

						referenceNum: res.data.referenceNum,

						courierCode: res.data.courierCode,
						customerCode: res.data.customerCode,
						accountCode: res.data.accountCode,

						shipperCode: res.data.accountCode,
						shipperName: res.data.shipperName,
						shipperAddress: res.data.shipperAddress,
						shipperProvince: res.data.shipperProvince,
						shipperProvinceName: res.data.shipperProvinceName,
						shipperCity: res.data.shipperCity,
						shipperCityName: res.data.shipperCityName,
						shipperDistrict: res.data.shipperDistrict,
						shipperDistrictName: res.data.shipperDistrictName,
						shipperSubDistrict: res.data.shipperSubDistrict,
						shipperSubDistrictName: res.data.shipperSubDistrictName,
						shipperPhone: res.data.shipperPhone,
						shipperEmail: res.data.shipperEmail,

						consigneeName: res.data.consigneeName,
						consigneeAddress: res.data.consigneeAddress,
						consigneeProvince: res.data.consigneeProvince,
						consigneeProvinceName: res.data.consigneeProvinceName,
						consigneeCity: res.data.consigneeCity,
						consigneeCityName: res.data.consigneeCityName,
						consigneeDistrict: res.data.consigneeDistrict,
						consigneeDistrictName: res.data.consigneeDistrictName,
						consigneeSubDistrict: res.data.consigneeSubDistrict,
						consigneeSubDistrictName: res.data.consigneeSubDistrictName,
						consigneePostalCode: res.data.consigneePostalCode,
						consigneePhone: res.data.consigneePhone,
						consigneeEmail: res.data.consigneeEmail,

						packageType: res.data.packageType,
						packageDescr: res.data.packageDescr,
						packageLength: res.data.packageLength,
						packageWidth: res.data.packageWidth,
						packageHeight: res.data.packageHeight,
						packageWeight: res.data.packageWeight,
						packageValue: res.data.packageValue,

						isCod: res.data.isCod ?? false,
						isInsurance: res.data.isInsurance ?? false,
						isRepacking: res.data.isRepacking ?? false,

						specialInstruction: res.data.specialInstruction,
					})

					this.onChangeShipperProvince({ value: res.data.shipperProvince, label: res.data.shipperProvinceName }, { action: "select-option" })
					this.onChangeShipperCity({ value: res.data.shipperCity, label: res.data.shipperCityName }, { action: "select-option" })
					this.onChangeShipperDistrict({ value: res.data.shipperDistrict, label: res.data.shipperDistrictName }, { action: "select-option" })
					this.onChangeShipperSubDistrict({ value: res.data.shipperSubDistrict, label: res.data.shipperSubDistrictName }, { action: "select-option" })
					this.onChangeShipperPostcode({ value: res.data.shipperPostalCode, label: res.data.shipperPostalCode }, { action: "select-option" })

					this.onChangeConsigneeProvince({ value: res.data.consigneeProvince, label: res.data.consigneeProvinceName }, { action: "select-option" })
					this.onChangeConsigneeCity({ value: res.data.consigneeCity, label: res.data.consigneeCityName }, { action: "select-option" })
					this.onChangeConsigneeDistrict({ value: res.data.consigneeDistrict, label: res.data.consigneeDistrictName }, { action: "select-option" })
					this.onChangeConsigneeSubDistrict({ value: res.data.consigneeSubDistrict, label: res.data.consigneeSubDistrictName }, { action: "select-option" })
					this.onChangeConsigneePostcode({ value: res.data.consigneePostalCode, label: res.data.consigneePostalCode }, { action: "select-option" })


				}).catch(err => {

				})

		}

	}
	componentWillUnmount() {
		// this.context.handleSetPageSidebarMinified(false);
	}

	render() {
		return (
			<div>
				<ol className="breadcrumb float-xl-right">
					<li className="breadcrumb-item"><Link to="/shipment"><span className="fa fa-angle-double-left"></span> Shipment List</Link></li>
				</ol>
				<h1 className="page-header">{!this.state.uuid ? "New" : "Update"} Shipment <small>... {this.state.shipmentStatus}</small></h1>
				<Panel>
					<PanelHeader className="bg-orange-transparent-9 text-right p-4 p-r-0">
						<div className="col-sm-12 p-r-0">
							<div className="btn-group btn-group-sm p-r-0">
								<button className="btn btn-sm btn-primary f-s-11 text-center" onClick={this.save} disabled={(parseInt(this.state.shipmentStatus) > 100)}><i className="fa fa-save"></i> {!this.state.uuid ? "Create" : "Update"}</button>
								{(parseInt(this.state.shipmentStatus) <= 100) &&  this.state.shipmentStatus !== 0 ? (<UncontrolledButtonDropdown>
									<DropdownToggle className={"btn btn-sm"} color="primary" caret></DropdownToggle>
									<DropdownMenu>
										<DropdownItem className="btn btn-sm btn-primary f-s-11 text-center text-danger" onClick={this.confirm}><i className="fa fa-thumbs-up"></i> Confirm</DropdownItem>
									</DropdownMenu>
								</UncontrolledButtonDropdown>) : null}
							</div>
							{(this.state.extWaybillNum) ? <button className="btn btn-sm btn-green f-s-11 text-center ml-3" onClick={this.printlabel}><i className="fa fa-print"></i> Print</button> : null}
							<button className="btn btn-sm btn-grey f-s-11 text-center ml-3" onClick={() => { }}><i className="fa fa-minus-circle"></i> Cancel</button>

						</div>
					</PanelHeader>
					<PanelBody>
						<div className='row mb-2 p-0'>
							<div className="row m-0 p-0">
								<div className="col-sm-6 m-0 p-0">
									<div className="row" >
										<div className="col-sm-6 pl-3" style={{}}>
											<div className="form-group row m-b-1">
												<label className="col-sm-5 col-form-label">Shipment Date</label>
												<div className="col-sm-7">
													<input type="date" name="shipmentDate" value={this.state.shipmentDate ?? ""} readOnly={this.state.uuid}
														onChange={this._handleOnChangeInputText} className="form-control form-control-sm" />
												</div>
											</div>
											<div className="form-group row m-b-1">
												<label className="col-sm-5 col-form-label">Shipment No</label>
												<div className="col-sm-7">
													<input type="text" name="shipmentNum" className="form-control form-control-sm"
														onChange={this._handleOnChangeInputText} value={this.state.shipmentNum ?? ""} placeholder="Shipment No" readOnly={this.state.uuid} disabled={this.state.uuid} />
												</div>
											</div>
										</div>
										<div className="col-sm-6">
											<div className="form-group row m-b-1">
												<label className="col-sm-6 col-form-label">Waybill No/ Resi</label>
												<div className="col-sm-6">
													<input type="text" className="form-control form-control-sm" value={this.state.waybillNum ?? ""} readOnly={true} disabled={true} />
												</div>
											</div>
											<div className="form-group row m-b-1">
												<label className="col-sm-6 col-form-label">Status</label>
												<div className="col-sm-6">
													<select className="form-control form-control-sm" disabled={true} value={this.state.shipmentStatus}>
														<option value="">DRAFT</option>
														{this.state.statuses.map((val, key) => {
															return (<option key={val.value} value={val.value}>{val.label}</option>)
														})}
													</select>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-sm-3">
									<div className="row">
										<div className="col-sm-12">
											<div className="form-group row m-b-1">
												<label className="col-sm-5 col-form-label text-right">Courier</label>
												<div className="col-sm-7">
													<Select name="courierCode" placeholder={"Courier"}
														value={this.state.mCouriers.find(({ value }) => { return value === this.state.courierCode })}
														onChange={(e) => { this.setState({ courierCode: e.value }) }} options={this.state.mCouriers}
														isDisabled={(parseInt(this.state.shipmentStatus) > 100)} />
												</div>
											</div>
											<div className="form-group row m-b-1">
												<label className="col-sm-5 col-form-label text-right">Ext. Waybill No</label>
												<div className="col-sm-7">
													<input type="text" className="form-control form-control-sm"
														value={this.state.extWaybillNum ?? ""} readOnly disabled={true} /> 
												</div>
											</div>

										</div>
									</div>
								</div>
								<div className="col-sm-3">
									<div className="row">
										<div className="col-sm-12">
											<div className="form-group row m-b-1">
												<label className="col-sm-5 col-form-label text-right">Service Type</label>
												<div className="col-sm-7">
													<Select name="serviceType" placeholder={"Service Type"}
														value={this.state.mServiceType.find(({ value }) => { return value === this.state.serviceType })}
														onChange={(e) => { this.setState({ serviceType: e.value }) }} options={this.state.mServiceType} 
														isDisabled={(parseInt(this.state.shipmentStatus) > 100)} />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
						<div className="row">
							<div className='col-sm-8'>
								<div className='row'>
									<div className='col-sm-6 pt-2'>
										<div className="form-group row">
											<label className="col-sm-3 col-form-label">Shipper</label>
											<div className="col-sm-7">
												<Select name="shipperProvince" placeholder={"Shipper"}
													value={this.state.shippers.filter(({ value }) => { return value === this.state.shipperCode; })}
													options={this.state.shippers}
													onChange={this.onChangeShipperCode}
													isClearable={false} isSearchable={true} isDisabled={(parseInt(this.state.shipmentStatus) > 100)} />
											</div>
										</div>
										<div className="form-group row mb-1">
											<div className="col-sm-12">
												<input type="text" name="shipperName" value={this.state.shipperName ?? ""}
													onChange={this._handleOnChangeInputText}
													className="form-control form-control-sm" placeholder="Shipper Name" />
											</div>
										</div>
										<div className="form-group row mb-1">
											<div className="col-sm-12">
												<textarea name="shipperAddress"
													onChange={this._handleOnChangeInputText} value={this.state.shipperAddress}
													className='form-control form-control-sm' placeholder="Shipper Address"></textarea>
											</div>
										</div>
										<div className="form-group row mb-1">
											<div className="col-sm-6">
												<Select name="shipperProvince" placeholder={"Province"}
													value={this.state.provinces.filter(({ value }) => { return value === this.state.shipperProvince; })}
													options={this.state.provinces}
													onChange={this.onChangeShipperProvince}
													isClearable={true} isSearchable={true} />
											</div>
											<div className="col-sm-6">
												<Select name="shipperCity" placeholder={"City"}
													value={this.state.citiesShipper.find(({ value }) => { return value === this.state.shipperCity })}
													options={this.state.citiesShipper} onChange={this.onChangeShipperCity}
													isClearable={true} isSearchable={true} />
											</div>
										</div>
										<div className="form-group row mb-1">
											<div className="col-sm-6">
												<Select name="shipperDistrict" placeholder={"District"}
													value={this.state.districtsShipper.find(({ value }) => { return value === this.state.shipperDistrict })}
													getOptionLabel={({ label }) => label}
													getOptionValue={({ value }) => value}
													options={this.state.districtsShipper}
													onChange={this.onChangeShipperDistrict}
													isClearable={true} isSearchable={true} />
											</div>
											<div className="col-sm-6">
												<Select name="shipperSubDistrict" placeholder={"Sub District"}
													value={this.state.subdistrictsShipper.find(({ value }) => { return value === this.state.shipperSubDistrict })}
													options={this.state.subdistrictsShipper}
													onChange={this.onChangeShipperSubDistrict}
													isClearable={true} isSearchable={true} />

											</div>
										</div>
										<div className="form-group row mb-1">
											<div className="col-sm-6">
												<Select name="shipperPostalCode" placeholder={"Postal Code"}
													value={this.state.postalcodesShipper.find(({ value }) => { return value === this.state.shipperPostalCode })}
													options={this.state.postalcodesShipper} onChange={this.onChangeShipperPostcode}
													isClearable={true} isSearchable={true} />
											</div>
											<div className="col-sm-6">
												<input type="text" value={this.state.originHub ?? ""} className="form-control form-control-md bg-grey-transparent-1" readOnly disabled />
											</div>
										</div>
										<div className="form-group row mb-1">
											<div className="col-sm-6">
												<input type="text" name="shipperPhone" value={this.state.shipperPhone ?? ""}
													onChange={this._handleOnChangeInputText}
													className="form-control form-control-sm" placeholder="Shipper Phone" />
											</div>
											<div className="col-sm-6">
												<input type="text" name="shipperEmail" value={this.state.shipperEmail ?? ""}
													onChange={this._handleOnChangeInputText}
													className="form-control form-control-sm" placeholder="Shipper Email" />
											</div>
										</div>
									</div>

									<div className='col-sm-6 bg-grey-transparent-3 pt-2'>
										<div className="form-group row">
											<label className="col-sm-3 col-form-label">Consignee</label>
											<div className="col-sm-7">
												<input type="text" className="form-control form-control-sm" value={""} placeholder="" disabled={true} />
											</div>
										</div>
										<div className="form-group row mb-1">
											<div className="col-sm-12">
												<input type="text" name="consigneeName" value={this.state.consigneeName ?? ""}
													onChange={this._handleOnChangeInputText}
													className="form-control form-control-sm" placeholder="Consignee Name" />
											</div>
										</div>
										<div className="form-group row mb-1">
											<div className="col-sm-12">
												<textarea name="consigneeAddress"
													onChange={this._handleOnChangeInputText} value={this.state.consigneeAddress ?? ""}
													className='form-control form-control-sm' placeholder="Consignee Address" ></textarea>
											</div>
										</div>
										<div className="form-group row mb-1">
											<div className="col-sm-6">
												<Select name="consigneeProvince" placeholder={"Province"}
													value={this.state.provinces.find(({ value }) => { return value === this.state.consigneeProvince })}
													options={this.state.provinces}
													onChange={this.onChangeConsigneeProvince}
													isClearable={true} isSearchable={true} />
											</div>
											<div className="col-sm-6">
												<Select name="consigneeCity" placeholder={"City"}
													value={this.state.citiesConsignee.find(({ value }) => { return value === this.state.consigneeCity })}
													options={this.state.citiesConsignee}
													onChange={this.onChangeConsigneeCity}
													isClearable={true} isSearchable={true} />
											</div>
										</div>
										<div className="form-group row mb-1">
											<div className="col-sm-6">
												<Select name="consigneeDistrict" placeholder={"District"}
													value={this.state.mDistrictConsignee.find(({ value }) => { return value === this.state.consigneeDistrict; })}
													options={this.state.mDistrictConsignee} onChange={this.onChangeConsigneeDistrict}

													isClearable={true} isSearchable={true} />
											</div>
											<div className="col-sm-6">
												<Select name="consigneeSubDistrict" placeholder={"Sub District"}
													value={this.state.mSubDistrictConsignee.find(({ value }) => { return value === this.state.consigneeSubDistrict })}
													options={this.state.mSubDistrictConsignee} onChange={this.onChangeConsigneeSubDistrict}
													isClearable={true} isSearchable={true} />
											</div>
										</div>
										<div className="form-group row mb-1">
											<div className="col-sm-6">
												<Select name="consigneePostalCode" placeholder={"Postal Code"}
													value={this.state.postalcodesConsignee.find(({ value }) => { return value === this.state.consigneePostalCode })}
													options={this.state.postalcodesConsignee} onChange={this.onChangeConsigneePostcode}
													isClearable={true} isSearchable={true} />
											</div>
											<div className="col-sm-6">
												<input type="text" value={this.state.originHub ?? ""} className="form-control form-control-md bg-grey-transparent-1" readOnly disabled />
											</div>
										</div>
										<div className="form-group row mb-1">
											<div className="col-sm-6">
												<input type="text" name="consigneePhone" value={this.state.consigneePhone ?? ""}
													onChange={this._handleOnChangeInputText}
													className="form-control form-control-sm" placeholder="Consignee Phone" />
											</div>
											<div className="col-sm-6">
												<input type="text" name="consigneeEmail" value={this.state.consigneeEmail ?? ""}
													onChange={this._handleOnChangeInputText}
													className="form-control form-control-sm" placeholder="Consignee Email" />
											</div>
										</div>
									</div>

								</div>
								<div className='row mt-4'>
									<div className='col-sm-6'>
										<h5>Package Information</h5>
										<div className="form-group mb-1">
											<label className="mb-0 f-s-11">Package Type</label>
											<CreatableSelect name="packageType" placeholder={"Package Type"} isClearable options={this.state.mPackageType}
												value={this.state.mPackageType.find(({ value }) => { return value === this.state.packageType })}
												onChange={(e) => { this.setState({ packageType: e.value }) }}
											/>

										</div>

										<div className="row mb-0">
											<div className="form-group mb-1">
												<label className="mb-0 f-s-11">Package Description</label>
												<div className="input-group input-group-sm m-b-10">
													<div className="input-group-prepend-sm col-sm-8 p-0">
														<input type="text" name="packageDescr" value={this.state.packageDescr ?? ""}
															onChange={this._handleOnChangeInputText}
															className="form-control form-control-sm" placeholder="" />
													</div>
													<input type="number" name="shipmentQtyCollies" value={this.state.shipmentQtyCollies ?? 0}
														onChange={this._handleOnChangeReactSelect}
														className="form-control form-control-sm ml-1 text-right" disabled={true} />
													<div className="input-group-append input-group-append-sm"><span className="input-group-text input-group-text-sm">COLLIE</span></div>
												</div>

												<div className="input-group input-group-sm m-b-10">

												</div>
											</div>

											<div className="col-sm-3">
												<div className="form-group">
													<label className="mb-0 f-s-11">Length (cm)</label>
													<NumericFormat name="packageLength"
														value={this.state.packageLength} thousandSeparator="," onChange={this._handleOnChangeInputText}
														allowNegative={false} decimalScale={0}
														className="form-control form-control-sm text-right" />
												</div>
											</div>
											<div className="col-sm-3">
												<div className="form-group">
													<label className="mb-0 f-s-11">Width (cm)</label>
													<NumericFormat name="packageWidth"
														value={this.state.packageWidth} thousandSeparator="," onChange={this._handleOnChangeInputText}
														allowNegative={false} decimalScale={0}
														className="form-control form-control-sm text-right" />
												</div>
											</div>
											<div className="col-sm-3">
												<div className="form-group">
													<label className="mb-0 f-s-11">Height (cm)</label>
													<NumericFormat name="packageHeight"
														value={this.state.packageHeight} thousandSeparator="," onChange={this._handleOnChangeInputText}
														allowNegative={false} decimalScale={0}
														className="form-control form-control-sm text-right" />
												</div>
											</div>
											<div className="col-sm-3">
												<div className="form-group">
													<label className="mb-0 f-s-11">Volume (m3)</label>
													<input type="number" name="packageVolume" value={this.calculateVolume()}
														className="form-control form-control-sm text-right" readOnly disabled={true} />
												</div>
											</div>
										</div>
										<div className="row mb-0">
											<div className="col-sm-6 mb-0">
												<div className="form-group">
													<label className="mb-0 f-s-11">Gross Weight (kg)</label>
													<NumericFormat name="packageWeight"
														value={this.state.packageWeight} thousandSeparator="," onChange={this._handleOnChangeInputText}
														allowNegative={false} decimalScale={2} fixedDecimalScale
														isAllowed={(values) => {
															const { floatValue } = values;
															return floatValue <= 1000;
														}}
														className="form-control form-control-sm text-right" />
												</div>
											</div>
											<div className="col-sm-6">
												<div className="form-group">
													<label className="mb-0 f-s-11">Package Value</label>
													<NumericFormat name="packageValue"
														value={this.state.packageValue} thousandSeparator="," onChange={this._handleOnChangeInputText}
														allowNegative={false} allowedDecimalSeparators={['%']} decimalScale={0}
														className="form-control form-control-sm text-right"
														isAllowed={(values) => {
															const { floatValue } = values;
															return floatValue <= 100000000;
														}}
													/>
												</div>
											</div>
										</div>
									</div>

									<div className='col-sm-6'>
										<div className="row my-0 py-0">
											<div className="row">
												<div className="col-sm-4 mb-0">
													<div className="form-group mb-0">
														<label className="mb-0 f-s-11">COD</label>
														<div className="input-group input-group-sm m-b-10">
															<div className="input-group m-b-10">
																<Switch id="isCod" onChange={this._handleOnChangeSwitch} checked={this.state.isCod ?? false} />
															</div>
														</div>
													</div>
												</div>
												<div className="col-sm-4 mb-0">
													<div className="form-group mb-0">
														<label className="mb-0 f-s-11">Insurance</label>
														<div className="input-group input-group-sm m-b-10">
															<div className="input-group m-b-10">
																<Switch id="isInsurance" onChange={this._handleOnChangeSwitch} checked={this.state.isInsurance ?? false} />
															</div>
														</div>
													</div>
												</div>
												<div className="col-sm-4 mb-0">
													<div className="form-group mb-0">
														<label className="mb-0 f-s-11">Repacking</label>
														<div className="input-group input-group-sm m-b-10">
															<div className="input-group m-b-10">
																<Switch id="isRepacking" onChange={this._handleOnChangeSwitch} checked={this.state.isRepacking ?? false} />
															</div>
														</div>
													</div>
												</div>

												<div className="form-group mb-1">
													<label className="mb-0 f-s-11">Special Instruction</label>
													<div className="input-group input-group-sm m-b-10">
														<textarea name="specialInstruction" onChange={this._handleOnChangeInputText} value={this.state.specialInstruction ?? ""}
															className='form-control form-control-sm' placeholder="Special Instruction"></textarea>
													</div>
												</div>
											</div>
										</div>

									</div>
								</div>
							</div>

							<div className='col-sm-4'>
								<div className="row">
									<div className='col-sm-12'>
										<Tabs>
											<TabList>
												<Tab>Delivery Cost</Tab>
												<Tab>History</Tab>
												<Tab>System References</Tab>
											</TabList>

											<TabPanel>
												<div className='row'>
													<div className='col-sm-12 table-responsive'>
														<table className="table table-hover">
															<thead>
																<tr>
																	<th>Item</th>
																	<th className='text-right'>Amount</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td><span className="f-s-11"> Chargeable Weight</span></td>
																	<td className="with-input-control m-0 p-3">
																		<div className="input-group input-group-sm">
																			<div className="input-group input-group-sm">
																				<div className="input-group-prepend">
																					<span className="input-group-text">Kg.</span>
																				</div>
																				<input type="text" className="form-control form-control-sm text-right" value={""} disabled={true} />
																			</div>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td><span className="f-s-11">Rate /Kg</span></td>
																	<td className="with-input-control m-0 p-3">
																		<div className="input-group input-group-sm">
																			<div className="input-group input-group-sm">
																				<div className="input-group-prepend">
																					<span className="input-group-text">Rp.</span>
																				</div>
																				<input type="text" className="form-control form-control-sm text-right" value={""}  disabled={true} />
																			</div>
																		</div>
																	</td>
																</tr>
																<tr className='table-info mt-5'>
																	<td><span className="f-s-11"> Sub Total</span></td>
																	<td className="with-input-control m-0 p-3">
																		<div className="input-group input-group-sm">
																			<div className="input-group input-group-sm">
																				<div className="input-group-prepend">
																					<span className="input-group-text">Rp.</span>
																				</div>
																				<input type="text" className="form-control form-control-sm text-right" value={""}  disabled={true} />
																			</div>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td className='with-checkbox'><span className="f-s-11">Insurance</span></td>
																	<td className="with-input-control m-0 p-3">
																		<div className="input-group input-group-sm">
																			<div className="input-group input-group-sm">
																				<div className="input-group-prepend">
																					<span className="input-group-text">Rp.</span>
																				</div>
																				<input type="text" className="form-control form-control-sm text-right" value={""}  disabled={true} />
																			</div>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td><span className="f-s-11">Repacking</span></td>
																	<td className="with-input-control m-0 p-3">
																		<div className="input-group input-group-sm">
																			<div className="input-group input-group-sm">
																				<div className="input-group-prepend">
																					<span className="input-group-text">Rp.</span>
																				</div>
																				<input type="text" className="form-control form-control-sm text-right" value={""}  disabled={true} />
																			</div>
																		</div>
																	</td>
																</tr>
																<tr className="table-danger">
																	<td><span className="f-s-15">Total</span></td>
																	<td className="with-input-control m-0 p-3">
																		<div className="input-group input-group-sm">
																			<div className="input-group input-group-sm">
																				<div className="input-group-prepend">
																					<span className="input-group-text">Rp.</span>
																				</div>
																				<input type="text" className="form-control form-control-sm text-right" value={""}  disabled={true} />
																			</div>
																		</div>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>
												<div className="row">

												</div>

											</TabPanel>
											<TabPanel>
												<h2>History Content</h2>
											</TabPanel>
											<TabPanel>
												<div className='row'>
													<div className="form-group row m-b-1">
														<label className="col-sm-5 col-form-label">Internal Number</label>
														<div className="col-sm-7">
															<input type="text" name="internalNum" className="form-control form-control-sm" value={this.state.internalNum}  disabled={true} />
														</div>
													</div>
													<div className="form-group row m-b-1">
														<label className="col-sm-5 col-form-label">Cust. Reference</label>
														<div className="col-sm-7">
															<input type="text" name="referenceNum" className="form-control form-control-sm" value={this.state.referenceNum} onChange={this._handleOnChangeInputText} placeholder="" />
														</div>
													</div>

												</div>
											</TabPanel>
										</Tabs>
									</div>
								</div>

							</div>

						</div>
					</PanelBody>
					<PanelFooter className=" bg-grey-transparent-1">
						<strong>CHILDREN {this.state.packageType}</strong>
					</PanelFooter>
				</Panel>
				<NotificationContainer />
			</div>
		)
	}
}

export default withParams(Shipment);