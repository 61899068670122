import React from 'react';
import { Route, withRouter, Redirect } from 'react-router-dom';
import Login from './../../pages/Login'; 
import routes from './../../config/page-route.jsx';
import { PageSettings } from './../../config/page-settings.js';
import {isAuthenticated} from "./../../helper/helper.js"


function setTitle(path, routeArray) {
	var pageTitle;
	for (var i = 0; i < routeArray.length; i++) {
		if (routeArray[i].path === path) {
			pageTitle = 'Xpress App | ' + routeArray[i].title;
		}
	}
	document.title = (pageTitle) ? pageTitle : 'Xpress App | React App';
}

class Content extends React.Component {
	componentDidMount() {
		setTitle(this.props.history.location.pathname, routes);
	}
	UNSAFE_componentWillMount() {
		this.props.history.listen(() => {
			setTitle(this.props.history.location.pathname, routes);
		});
	}

	render() {  
		return (
			<PageSettings.Consumer>
				{({ pageContentFullWidth, pageContentClass, pageContentInverseMode, appAuth }) => (
					<div className={'content ' + (pageContentFullWidth ? 'content-full-width ' : '') + (pageContentInverseMode ? 'content-inverse-mode ' : '') + pageContentClass}>

						<Route path={"/login"} exact={true} component={Login} /> 

						{routes.map((route, index) => (
							((isAuthenticated() ) === true) ? <Route
								key={`${route.path}`}
								path={route.path}
								exact={route.exact}
								component={route.component}
							/>
								: 
								<Redirect key={`${route.path}`} push to={"/login"} />

						))}

					</div>
				)
				}
			</PageSettings.Consumer>
		)
	}
}

export default withRouter(Content);
