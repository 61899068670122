import React from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom';
import { Panel, PanelBody, PanelHeader } from '../../components/panel/panel.jsx';
import { PageSettings } from '../../config/page-settings.js';
import 'react-tabs/style/react-tabs.css';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { isEmpty } from "../../helper/helper.js"

class UnBagging extends React.Component {

  static contextType = PageSettings;

  constructor(props) {
    super(props);
    this.focusPackageNum = this.focusPackageNum.bind(this)
    this.focusBagNum = this.focusBagNum.bind(this)
  }

  focusPackageNum = () => {
    try {
      this.textPackageNum.focus();
    } catch (err) { }
  }

  focusBagNum = () => {
    try {
      this.textbagNum.focus();
    } catch (err) { }
  }

  state = {
    bagContent: [],
    packageNum: "",
    bagNum: "BX000001",
    bagStatus: null,
    shipmentNum: null,
    waybillNum: null,
    hubDestination: ""
  }

  checkUnBag = async () => {
    if (isEmpty(this.state.bagNum)) {
      await NotificationManager.error(`Please input Bag Number!!!`);
      return;
    }

    await axios.post(`${window.APP_API}/api/v1/bag/checkunbag`,
      { bagNum: this.state.bagNum }
    )
      .then(res => {

        this.setState({
          bagContent: res.data.bags ?? [],
          bagStatus: res.data.bagStatus,
        })

        this.focusPackageNum()
      }).catch(async (err) => {
        await NotificationManager.error(`Failed  ${err.response.data.message}`);
      })

  }

  searchPackage = async () => {
    if (isEmpty(this.state.packageNum) || isEmpty(this.state.bagNum)) {
      await NotificationManager.warning(`Please input Bag / Package Number !!!`);
      return;
    }

    await axios.post(`${window.APP_API}/api/v1/bag/bag`,
      { bagNum: this.state.bagNum, packageNum: this.state.packageNum }
    ).then(async (res) => {

      this.setState(prevState => ({
        bagContent: [res.data.detail, ...prevState.bagContent],
        bagStatus: res.data.bagStatus
      }))

      this.setState({ packageNum: "" })

    }).catch(async (err) => {
      this.setState({ packageNum: "" })
      await NotificationManager.error(`Failed  ${err.response.data.message}`);
    })
  }

  unbag = val => async () => {

    await axios.post(`${window.APP_API}/api/v1/bag/unbag`,
      { bagNum: this.state.bagNum, packageNum: val }
    ).then(async (res) => {
      await NotificationManager.success(res.data.message);

      const bagContent = this.state.bagContent;
      const pckg = bagContent.findIndex(item => item.package_num === val);

      bagContent[pckg].status = "UNBAG";
      this.setState({
        bagContent: bagContent,
      });

    }).catch(async (err) => {
      await NotificationManager.error(`Failed  ${err.response.data.message}`);
    })
  }

  reset = async () => {
    this.setState({
      bagNum: "",
      packageNum: "",
      bagStatus: "",
      bagContent: [],
    });

    this.focusBagNum()
  }

  _handleKeyDown = (e) => {
    if (e.target.name === "bagNum" && e.keyCode === 13) { // if enter or tab
      this.checkUnBag()
    }
    if (e.target.name === "packageNum" && e.keyCode === 13) {
      this.searchPackage()
    }
  }

  _handleOnChangeInputText = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  async componentDidMount() {

    this.context.handleSetPageSidebarMinified(true);
    this.context.handleSetPageFooter(false);
    this.focusBagNum();

  }

  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item"><Link to="/">Home</Link></li>
          <li className="breadcrumb-item active">Active Page</li>
        </ol>
        <h1 className="page-header">UnBagging <small>Scan Package to unbag</small></h1>

        <Panel>
          <PanelHeader className="bg-orange-transparent-9 text-right p-4 p-r-0">
            <div className="col-sm-12 p-r-0">
              <button className="btn btn-sm btn-danger f-s-11 text-center ml-3" onClick={this.reset}><i className="fa fa-minus-circle"></i> Reset</button>
            </div>
          </PanelHeader>
          <PanelBody>
            <div className="row" >
              <div className="col-sm-6 col-md-6">

                <div className="row mb-3" >
                  <div className="col-md-7">
                    <label className="form-label">BAGGING NO</label>
                    <div className="input-group">
                      <input className="form-control form-control-lg" type="text" name="bagNum" onChange={this._handleOnChangeInputText} onKeyDown={this._handleKeyDown} value={this.state.bagNum ?? ""} placeholder="Bag Number" required="" ref={(input) => { this.textbagNum = input; }} disabled={["OPEN", "CLOSE"].includes(this.state.bagStatus)} />
                      <div className="input-group-text m-0 p-0">
                      </div>
                    </div>
                  </div>
                </div>
                {["CLOSE"].includes(this.state.bagStatus) ?
                  <div className="row mb-3" >
                    <div className="col-md-7">
                      <label className="form-label">PACKAGE NO</label>
                      <div className="input-group">
                        <input className="form-control form-control-lg" type="text" name="packageNum" onChange={this._handleOnChangeInputText} onKeyDown={this.unbag(this.state.packageNum)} value={this.state.packageNum ?? ""} placeholder="Shipment / Package No" required="" ref={(input) => { this.textPackageNum = input; }} />
                        <div className="input-group-text m-0 p-0">
                          <span className="fas fa-cube text-primary fs-2"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  : null}

              </div>

            </div>
            <div className="row">
              <div className="col-sm-6 col-md-6 pl-2">
                <table className="table table-hover table-striped table-sm tbl-receiving" id="tbl-receiving">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Package</th>
                      <th scope="col">Type</th>
                      <th scope="col">Status</th>
                      <th>&nbsp; <i className="fa fa-cog"></i></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.bagContent.map((val, index) => {
                      return (
                        <tr key={val.id}>
                          <td>{(this.state.bagContent.length) - (index)}.</td>
                          <td>{val.package_num}</td>
                          <td>{val.package_type}</td>
                          <td>{val.status}</td>
                          <td className="pl-10">{val.status === "BAG" ? <button className="btn btn-xs btn-green m-0" onClick={this.unbag(val.package_num)}><i className="fa fa-sm fa-arrow-alt-circle-right" tooltips="Unbagging" title={`Unbag ${val.package_num}`}></i></button> : null}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </PanelBody>
        </Panel>
        <NotificationContainer />
      </div>
    )
  }

}

export default UnBagging;