import React from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody, } from '../../components/panel/panel.jsx';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Select from 'react-select';
import { PageSettings } from '../../config/page-settings.js';
import moment from "moment"
import Swal from 'sweetalert2'
import { isEmpty } from '../../helper/helper.js';

class ShipmentBulkyUpload extends React.Component {

  static contextType = PageSettings;

  state = {
    accounts: [],
    accCode: "",
    accName: "",
    data: [],
    dataSuccess: [],
    dataFailed: [],
    selectedFile: null,
    disableUpload: false,
  }

  _handleOnChangeInputFile = async (e) => {
    this.setState({ selectedFile: e.target.files[0] })
  }


  _handleOnChangeReactSelect = async (option, e) => {
    console.log(e);
    if (e.action === "select-option") {
      this.setState({ [e.name]: option.value, accName: option.label })
    } else if (e.action === "clear") {
      this.setState({ [e.name]: "", accName: "" });
    } else {
      // do nothing
    }

  }

  uploadFile = async () => {
    if (isEmpty(this.state.accCode)) {
      await NotificationManager.warning(`Select Account`);
      return;
    }

    if (!this.state.selectedFile) {
      await NotificationManager.warning(`File is empty`);
      return;
    }

    Swal.fire({
      text: `Bulky Upload Shipment Creation for Account ${this.state.accName}  ?`,
      icon: 'warning',
      confirmButtonText: 'Yes',
      showCloseButton: true,
      showCancelButton: true
    }).then(async (result) => {

      if (result.isConfirmed) {
        const formData = new FormData();

        // Update the formData object
        formData.append(
          "selectedFile",
          this.state.selectedFile,
          this.state.selectedFile.name
        );
        formData.append("accountNum", this.state.accCode)

        this.setState({ disableUpload: true })
        await axios.post(`${window.APP_API}/api/v1/shipment-bulky/order-upload`, formData).then(async (res) => {
          this.setState({
            dataFailed: res.data.errorResults ?? [],
            dataSuccess: res.data.successResults ?? [],
          })

          await NotificationManager.success(`${res.data.message ?? ''}`);
          this.setState({ disableUpload: false })
        }).catch(async (err) => {
          await NotificationManager.error(`Failed  ${err.response.data.message}`);
          this.setState({ disableUpload: false })
        })

      }
    })

  }

  resetBtn = () => {
    this.setState({
      dataSuccess: [],
      dataFailed: [],
      selectedFile: null, 
      accCode: "", 
      accName: ""
    })
  }

  async componentDidMount() {
    this.context.handleSetPageSidebarMinified(true);
    this.context.handleSetPageFooter(false);

    await axios.post(`${window.APP_API}/api/v1/ui/fshipment-bulkyupload`)
      .then(res => {
        this.setState({ accounts: res.data.accounts })
      }).catch(err => {

      })
  }

  render() {

    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item"><Link to="/">Home</Link></li>
          <li className="breadcrumb-item active">Active Page</li>
        </ol>
        <h1 className="page-header">Shipment Bulky Upload {this.state.fileName} <small> Upload multiple shipment creation</small></h1>

        <Panel>
          <PanelHeader btnToggle>Bulky Upload</PanelHeader>
          <PanelBody>
            <div className="row" >
              <div className='col-sm-6'>
                <div className="form-group row m-b-3">
                  <label className="col-sm-3 col-form-label">Account</label>
                  <div className="col-sm-8">
                    <Select name="accCode" placeholder={"Account"}
                      value={this.state.accounts.find(({ value }) => { return value === this.state.accCode }) ?? ""}
                      options={this.state.accounts}
                      onChange={this._handleOnChangeReactSelect}
                      isClearable={true} isSearchable={true} />
                  </div>
                </div>
                <div className="form-group row m-b-10">
                  <label className="col-sm-3 col-form-label">FileName</label>
                  <div className="col-sm-8">
                    <input className="form-control form-control-sm" type="file" name="selectedFile" id="file" onChange={this._handleOnChangeInputFile} />
                  </div>
                </div>
                <div className="form-group row m-b-1">
                  <label className="col-sm-3 col-form-label">&nbsp;</label>
                  <div className="col-sm-8">
                    <button type="button" className="btn btn-sm btn-primary " onClick={this.uploadFile} disabled={this.state.disableUpload}><span className="fa fa-sm fa-upload"></span> Upload</button>
                    <button type="button" className="btn btn-sm btn-danger ml-5" onClick={this.resetBtn}><span className="fa fa-sm fa-times-circle"></span> Clear</button>
                  </div>
                </div>
              </div>
            </div>
          </PanelBody>
        </Panel>

        <Panel>
          <PanelBody>
            <Tabs>
              <TabList>
                <Tab> <span className="fa fa-check-circle text-green"></span> Success {"{"}{this.state.dataSuccess.length}{"}"}</Tab>
                <Tab> <span className="fa fa-minus-circle text-red"></span> Failed {"{"}{this.state.dataFailed.length}{"}"}</Tab>
              </TabList>
              <TabPanel>
                <div className="table-responsive table-striped bg-white">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Shipment No</th>
                        <th>Date</th>
                        <th>Destination</th>
                        <th>Waybill No</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.dataSuccess.map((val, key) => {
                        return (
                          <tr key={val.shipmentNum}>
                            <td>{(key + 1)}.</td>
                            <td>{moment(val.shipmentDate).format("YYYY-MM-DD")}</td>
                            <td>{val.shipmentNum}</td>
                            <td>{val.destination}</td>
                            <td>{val.waybillNum}</td>

                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col-sm-12">
                    <button className='btn btn-sm btn-primary'><span className="fa fa-download"></span> Download Failed List</button>
                  </div>
                </div>
                <div className="table-responsive table-striped bg-white">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Shipment No</th>
                        <th>Failed Reason</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.dataFailed.map((val, key) => {
                        return (
                          <tr key={val.shipmentNum}>
                            <td>{(key + 1)}.</td>
                            <td>{val.shipmentNum}</td>
                            <td>{val.reason}</td>

                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </TabPanel>
            </Tabs>
          </PanelBody>

        </Panel>
        <NotificationContainer />
      </div>
    )
  }
}



export default ShipmentBulkyUpload;