import React from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody, PanelFooter } from '../../components/panel/panel.jsx';
import { PageSettings } from '../../config/page-settings.js';
import Pagination from '../../components/pagination/pagination.jsx';
import moment from "moment"

class ManifestList extends React.Component {

  static contextType = PageSettings;

  state = {
    currentPage: 1,
    totalRows: 0,
    data: [],
    provinceName: null,
    shipmentStatus: 0,
  }

  _handleOnChangeInputText = async (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  _handleOnChangeInputSelect = async (e) => {
    await this.setState({ [e.target.name]: e.target.value })
  }

  _handleKeyDown = (e) => {
    if (e.keyCode === 13) { // if enter or tab
      this.refreshTable()
    }
  }

  async componentDidMount() {
    const rowsPerPage = this.context.defaultRowsPerPage
    this.setState({ rowsPerPage: rowsPerPage })

    this.context.handleSetPageSidebarMinified(true);
    this.context.handleSetPageFooter(false);

    this.refreshTable()
  }


  onChangePageHandler = async (page) => {
    await this.setState({ currentPage: page })
    await this.refreshTable()
  }

  onChangePageSelectHandler = async (e) => {
    await this.setState({ [e.target.name]: e.target.value })
    await this.refreshTable()
  }


  refreshTable = async () => {
    const params = {}
    if (this.state.provinceName) params.name = this.state.provinceName

    params.page = this.state.currentPage;
    params.size = this.state.rowsPerPage;

    await axios.get(`${window.APP_API}/api/v1/manifest`, {
      params: params
    }).then(res => {
      this.setState({ data: res.data.data, totalRows: res.data.total })
    }).catch(err => {

    })
  }


  render() {

    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item"><Link to="/">Home</Link></li>
          <li className="breadcrumb-item active">Active Page</li>
        </ol>
        <h1 className="page-header">Manifest List <small>..</small></h1>

        <Panel>
          <PanelHeader btnToggle>Manifest Search</PanelHeader>
          <PanelBody>
            <div className="row" >
              <div className='col-sm-6'>
                <div className="form-group row m-b-1">
                  <label className="col-sm-3 col-form-label">Shipment No</label>
                  <div className="col-sm-8">
                    <input type="text" name="provinceName" value={this.state.provinceName ?? ""} onChange={this._handleOnChangeInputText} onKeyDown={this._handleKeyDown} className="form-control form-control-sm" />
                  </div>
                </div>
                <div className="form-group row m-b-1">
                  <label className="col-sm-3 col-form-label">&nbsp;</label>
                  <div className="col-sm-8">
                    <button type="button" className="btn btn-sm btn-primary" onClick={this.refreshTable}><span className="fa fa-sm fa-search"></span> Search</button>
                    <Link to="/manifest/create" className="btn btn-sm btn-grey ml-5"><span className="fa fa-plus-circle"></span> New</Link>
                  </div>
                </div>
              </div>
            </div>
          </PanelBody>
        </Panel>

        <Panel>
          <PanelBody>
            <div className="table-responsive table-striped bg-white">
              <table className="table">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Manifest No</th>
                    <th>Date</th>
                    <th>Origin</th>
                    <th>Destination</th>
                    <th>Transporter</th>
                    <th>Plate No</th>
                    <th>Status</th>
                    <th>Weight</th>
                    <th>Volume</th>
                    <th>Current</th>
                    <th>Updated At</th>
                    <th>Updated By</th>
                    <th>&nbsp;</th>
                  </tr>

                </thead>
                <tbody>
                  {this.state.data.map((val, key) => {
                    return (
                      <tr key={val.uuid}>
                        <td>{((this.state.currentPage - 1) * this.state.rowsPerPage) + (key + 1)}.</td>
                        <td>{moment(val.shipment_date).format("YYYY-MM-DD")}</td>
                        <td><Link to={`/shipment/${val.uuid}/view`}>{val.shipment_num}</Link></td>
                        <td>{val.reference_num}</td>
                        <td>{val.customer_code}</td>
                        <td>{val.account ? val.account.acc_name : "-"}</td>
                        <td>{val.consignee_name}</td>
                        <td>{val.consignee_city_name}</td>
                        <td>{val.consignee_district_name}</td>
                        <td>{val.consignee_postalcode}</td>
                        <td>{val.status.status_name}</td>
                        <td>{val.chargeable_weight ?? 0} Kg</td>
                        <td><button className="btn btn-xs btn-danger m-0"><i className="fa fa-sm fa-trash"></i></button></td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </PanelBody>
          <PanelFooter>
            <div className='row'>
              <div className="col-sm-12">
                <Pagination
                  currentPage={this.state.currentPage}
                  totalRows={this.state.totalRows}
                  pageSize={this.state.rowsPerPage ?? this.context.defaultRowsPerPage}
                  onPageChange={this.onChangePageHandler}
                  selectedPageHandler={this.onChangePageSelectHandler}
                />
              </div>
            </div>
          </PanelFooter>
        </Panel>

      </div>
    )
  }
}



export default ManifestList;