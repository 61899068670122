import axios from "axios";

export const axiosInstance = axios.create({
  // baseURL: process.env.REACT_APP_API,
});

axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const token = localStorage.getItem('token'); 
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    console.log(error.config);
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // const originalRequest = error.config; 
    return Promise.reject(error);
  }
);

