import { jwtDecode } from "jwt-decode"; 

export const numericValue =  (val) => {
  try{
    return parseFloat(val.toString().replace(/,/g, ''))
  }catch(err){ 
    return val
  } 
}

export const isEmpty = (val) => {
  try{
    return (val === null || val === "" || val === undefined) 
  }catch(err) {
    return false
  }
}

export const isAuthenticated = () => {  
  const token = localStorage.getItem("token") 
  if (token !== undefined) { 
    try {
      const jwt = jwtDecode(token);   
      if (Date.now() >= jwt.exp * 1000) { 
        return false; 
      } 
      return true;
    } catch (e) { 
      console.log(e)
      return false;
    } 
    
  }
  return false; 

}