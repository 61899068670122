import React from "react";
import { Link, useParams } from 'react-router-dom';
import Switch from "react-switch";
import axios from 'axios'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Panel,PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { PageSettings } from '../../config/page-settings.js'; 
import Select from 'react-select';

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class City extends React.Component {

  static contextType = PageSettings;

  state = {
    provinces: [],
    provinceCode: null,
    provinceName: null,
    active: true,

  }

  _handleOnChangeInputText = async (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  _handleOnChangeSwitch = async (c, e, id) => {
    this.setState({ [id]: c })
  }

  onChangeProvince = (option, e) => {
    if (e.action === "select-option") {
      this.setState({ provinceCode: option.value, provinceName: option.label })
    } else if (e.action === "clear") {
      this.setState({ provinceCode: null, provinceName: null })
    }
  }

  save = async (e) => {

    if(this.state.provinceCode === undefined || this.state.provinceCode === null) {
     await  NotificationManager.error(`Province is not allow Empty`);
      return;
    }

    if(this.state.cityCode === undefined || this.state.cityCode === null) {
      await NotificationManager.error(`City Code is not allow Empty`);
      return;
    }

    if(this.state.cityName === undefined || this.state.cityName === null) {
      await NotificationManager.error(`City Name is not allow Empty`);
      return;
    }

    let url = `${window.APP_API}/api/v1/city/new`
    if (this.state.uuid !== undefined) {
      url = `${window.APP_API}/api/v1/city/update/${this.state.uuid}`
    }

    const { provinceCode, provinceName, cityCode, cityName, active } = this.state

    await axios.post(url,
      { provinceCode, provinceName, cityCode, cityName, active }
    )
      .then( async(res) => {

        await NotificationManager.success(res.data.message);

        if (this.state.uuid === undefined) {
          setTimeout(() => {
            window.location.replace(`/master/city/view/${res.data.uuid}`);
          }, 3000)

        }

      }).catch(async(err) => {
        await  NotificationManager.error(`Failed  ${err.response.data.message}`);
      })

  }

  async componentDidMount() {
    await axios.get(`${window.APP_API}/api/v1/ui/province`)
      .then(res => {
        const data = res.data;
        this.setState({ provinces: data.provinces ?? [] })
      }).catch(err => {
      })

    if (this.props.params.uuid) {
      this.setState({ uuid: this.props.params.uuid })

      await axios.get(`${window.APP_API}/api/v1/city/view/${this.props.params.uuid}`)
        .then(async (res) => {
          this.setState({
            provinceCode: res.data.provinceCode,
            cityCode: res.data.cityCode,
            cityName: res.data.cityName,
            active: res.data.active
          })
        }).catch(err => {

        })
      this.context.handleSetPageSidebarMinified(true);
      this.context.handleSetPageFooter(false);
    }
  }

  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item"><Link to="/master/city"> <span className="fa fa-angle-double-left"></span> City List</Link></li>
        </ol>
        <h1 className="page-header">{!this.state.uuid ? "New" : "Update"} City <small>Maintenance Data</small></h1>
        <Panel>
        <PanelHeader className="bg-orange-transparent-9 text-right p-4 p-r-0">
						<div className="col-sm-12 p-r-0"> 
              <button className="btn btn-sm btn-primary f-s-11 text-center" onClick={this.save}><i className="fa fa-save"></i> {!this.state.uuid ? "Create" : "Update"}</button>
							<button className="btn btn-sm btn-grey f-s-11 text-center ml-3" onClick={() => { }}><i className="fa fa-minus-circle"></i> Cancel</button> 
						</div>
					</PanelHeader>
          <PanelBody>
            <div className="row" >
              <div className='col-sm-6'>
                <div className="form-group row m-b-3">
                  <label className="col-sm-3 col-form-label">Province</label>
                  <div className="col-sm-8">
                    <Select name="provinceCode" placeholder={"Province"}
                      value={this.state.provinces.filter(({ value }) => { return value === this.state.provinceCode; })}
                      options={this.state.provinces}
                      onChange={this.onChangeProvince}
                      isClearable={true} isSearchable={true} />
                  </div>
                </div>

                <div className="form-group row m-b-1">
                  <label className="col-sm-3 col-form-label">Code</label>
                  <div className="col-sm-8">
                    <input type="text" name="cityCode" value={this.state.cityCode} onChange={this._handleOnChangeInputText} className="form-control form-control-sm" disabled={this.state.uuid} />
                  </div>
                </div>
                <div className="form-group row m-b-1">
                  <label className="col-sm-3 col-form-label">Name</label>
                  <div className="col-sm-8">
                    <input type="text" name="cityName" value={this.state.cityName} onChange={this._handleOnChangeInputText} className="form-control form-control-sm" />
                  </div>
                </div>
                <div className="form-group row m-b-10">
                  <label className="col-sm-3 col-form-label">Active</label>
                  <div className="col-sm-8">
                    <Switch id="active" onChange={this._handleOnChangeSwitch} checked={this.state.active ?? false} />
                  </div>
                </div>
              </div>
            </div>
          </PanelBody>
        </Panel>
        <NotificationContainer />
      </div>
    )
  }
}

export default withParams(City);
