import React from 'react';
import ReactDOM from "react-dom/client";
import App from './app.jsx';
import { BrowserRouter } from 'react-router-dom';  

// css
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-quill/dist/quill.snow.css';
//import 'codemirror/lib/codemirror.css';
//import 'codemirror/theme/material.css';
import 'simple-line-icons/css/simple-line-icons.css';
//import 'flag-icon-css/css/flag-icon.min.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './index.css';
import './scss/react.scss';
import 'bootstrap-social/bootstrap-social.css';
import 'bootstrap-daterangepicker/daterangepicker.css'; 
import "./config/login-handler.js";

window.APP_API = process.env.REACT_APP_API;
const root = ReactDOM.createRoot(document.getElementById("root"));

// ======================================== 
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter> 
);
