// https://github.com/mayankshubham/react-pagination.git

import React from 'react';
import { usePagination, DOTS } from './usePagination';
import styles from "./styles.module.css";

const Pagination = props => {
  const {
    onPageChange,
    totalRows,
    siblingCount = 1,
    currentPage,
    pageSize,
    selectedPageHandler
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalRows,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 1) {
    return null;
  }

  // Onclick handlers for the butons
  const onNextPage = () => onPageChange(currentPage + 1);
  const onPrevPage = () => onPageChange(currentPage - 1);
  // const onPageSelect = (pageNo) => onPageChange(pageNo);
  // const onChangeRow = (e) => selectedPerPageHandler();

  let lastPage = paginationRange[paginationRange.length - 1];
  const pageFirstRecord = ((currentPage - 1) * pageSize) + 1
  const count = pageFirstRecord + pageSize;
  const pageLastRecord = count > totalRows ? totalRows : count - 1
  return (
    <>
      <div className={styles.pagination}>
        <div className={styles.pageInfo}>
          <div className="form-inline">
            Per Page: <select className="form-control form-control-sm" name="rowsPerPage" defaultValue={pageSize} onChange={selectedPageHandler}>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="500">500</option>
            </select>
            &nbsp; {paginationRange.length > 1 ? (<>show {pageFirstRecord} - {pageLastRecord} of {totalRows}</>) : null}
          </div>
        </div>

        {paginationRange.length > 1 ? (
          <div className={`${styles.pagebuttons}`}>
            {currentPage !== 1 ? (
              <button className={`btn btn-xs btn-default ${styles.pageBtn}`} onClick={() => onPrevPage()}> &#8249;  </button>
            ) : null}

            {paginationRange.map(pageNumber => {
              if (pageNumber === DOTS) {
                return <button key={`${pageNumber}dots`} className={`btn btn-sm btn-default ${styles.pageBtn}`} > &#8230;  </button>
              }

              return (
                <button key={pageNumber} onClick={() => onPageChange(pageNumber)}
                  className={`btn btn-xs ${styles.pageBtn} ${pageNumber === currentPage ? 'btn-primary' : "btn-default"}`} >
                  {pageNumber}
                </button>
              );
            })}
            {currentPage !== (lastPage) ? (
              <button className={`btn btn-xs btn-default ${styles.pageBtn}`} onClick={() => onNextPage()}> &#8250; </button>
            ) : null}

          </div>
        ) : null}
      </div>



    </>

  );
};

export default Pagination;