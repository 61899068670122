import React from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { PageSettings } from './../config/page-settings.js';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';


import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { Panel, PanelHeader, PanelBody, PanelFooter } from './../components/panel/panel.jsx';
import { Line } from 'react-chartjs-2';
import classnames from 'classnames';
import { Sparklines, SparklinesLine, SparklinesSpots,SparklinesBars } from 'react-sparklines';
import PerfectScrollbar from 'react-perfect-scrollbar';


const boxMullerRandom = () => {
  let phase = false,
    x1, x2, w, z;

  return (function () {

    if (phase = !phase) {
      do {
        x1 = 2.0 * Math.random() - 1.0;
        x2 = 2.0 * Math.random() - 1.0;
        w = x1 * x1 + x2 * x2;
      } while (w >= 1.0);

      w = Math.sqrt((-2.0 * Math.log(w)) / w);
      return x1 * w;
    } else {
      return x2 * w;
    }
  })();
}

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);

    this.state = {
      activeTab: '1'
    };

    this.lineChartData = {
      labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
      datasets: [{
        label: 'Page Views',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(0, 172, 172, 0.25)',
        borderColor: '#00acac',
        borderWidth: 2,
        pointBorderColor: '#00acac',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 2,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: '#00acac',
        pointHoverBorderWidth: 3,
        pointRadius: 3,
        pointHitRadius: 10,
        data: [65, 59, 80, 81, 56, 55, 40, 59, 76, 94, 77, 82]
      }, {
        label: 'Visitors',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(52, 143, 226, 0.25)',
        borderColor: '#348fe2',
        borderWidth: 2,
        pointBorderColor: '#348fe2',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 2,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: '#348fe2',
        pointHoverBorderWidth: 3,
        pointRadius: 3,
        pointHitRadius: 10,
        data: [25, 29, 40, 45, 16, 15, 20, 39, 26, 44, 57, 32]
      }]
    }

    this.lineChartOptions = {
      maintainAspectRatio: false,
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            fontColor: 'black'
          }
        }],
        xAxes: [{
          ticks: {
            fontColor: 'black'
          }
        }]
      },
      legend: {
        labels: {
          fontColor: 'black'
        }
      }
    }

    this.map = {
      center: {
        lat: 59.95,
        lng: 30.33
      },
      zoom: 9
    }

    this.date = new Date();

    this.sparklineData = [{
      values: [789, 880, 676, 200, 890, 677, 900],
      colors: { area: 'transparent', line: '#ff5b57' }
    }];

    this.sparklineData2 = [{
      values: [789, 880, 676, 200, 890, 677, 900],
      colors: { area: 'transparent', line: '#f59c1a' }
    }];

    this.sparklineData3 = [{
      values: [789, 880, 676, 200, 890, 677, 900],
      colors: { area: 'transparent', line: '#00acac' }
    }];

    this.sparklineData4 = [{
      values: [789, 880, 676, 200, 890, 677, 900],
      colors: { area: 'transparent', line: '#348fe2' }
    }];

    this.sparklineData5 = [{
      values: [789, 880, 676, 200, 890, 677, 900],
      colors: { area: 'transparent', line: '#ccc' }
    }];

    this.sparklineData6 = [{
      values: [789, 880, 676, 200, 890, 677, 900],
      colors: { area: 'transparent', line: '#2d353c' }
    }];
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  randomData(n = 30) {
    return Array.apply(0, Array(n)).map(boxMullerRandom);
  }

  render() {
    const randmn = this.randomData
    console.log(randmn)

    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item"><Link to="#">Home</Link></li>
          <li className="breadcrumb-item active">Dashboard</li>
        </ol>
        <h1 className="page-header">Dashboard <small>header small text goes here...</small></h1>

        <div className="row">
          <div className="col-xl-3 col-md-6">
            <div className="widget widget-stats bg-red">
              <div className="stats-icon"><i className="fa fa-desktop"></i></div>
              <div className="stats-info">
                <h4>TOTAL VISITORS</h4>
                <p>3,291,922</p>
              </div>
              <div className="stats-link">
                <Link to="#">View Detail <i className="fa fa-arrow-alt-circle-right"></i></Link>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div className="widget widget-stats bg-orange">
              <div className="stats-icon"><i className="fa fa-link"></i></div>
              <div className="stats-info">
                <h4>BOUNCE RATE</h4>
                <p>20.44%</p>
              </div>
              <div className="stats-link">
                <Link to="#">View Detail <i className="fa fa-arrow-alt-circle-right"></i></Link>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div className="widget widget-stats bg-grey-darker">
              <div className="stats-icon"><i className="fa fa-users"></i></div>
              <div className="stats-info">
                <h4>UNIQUE VISITORS</h4>
                <p>1,291,922</p>
              </div>
              <div className="stats-link">
                <Link to="#">View Detail <i className="fa fa-arrow-alt-circle-right"></i></Link>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div className="widget widget-stats bg-black-lighter">
              <div className="stats-icon"><i className="fa fa-clock"></i></div>
              <div className="stats-info">
                <h4>AVG TIME ON SITE</h4>
                <p>00:12:23</p>
              </div>
              <div className="stats-link">
                <Link to="#">View Detail <i className="fa fa-arrow-alt-circle-right"></i></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-8">
            <Panel>
              <PanelHeader>Website Analytics (Last 7 Days)</PanelHeader>
              <PanelBody>
                {/* <Line data={ this.lineChartData } height={ 300 } options={ this.lineChartOptions } /> */}
              </PanelBody>
            </Panel>
          </div>
          <div className="col-xl-4">
            <Panel>
              <PanelHeader>Analytics Details</PanelHeader>
              <PanelBody className="p-0">
                <div className="table-responsive">
                  <table className="table table-valign-middle mb-0">
                    <thead>
                      <tr>
                        <th className="p-l-15">Source</th>
                        <th>Total</th>
                        <th className="p-r-15">Trend</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="p-l-10">Total Delivery</td>
                        <td>13,203 <span className="text-success"><i className="fa fa-arrow-up"></i></span></td>
                        <td className="p-r-15">
                          <Sparklines data={[30, 40, 20.50, 30, 90, 70, 0, 100]}>
                            <SparklinesLine color="#56b45d" style={{ strokeWidth: 3, stroke: "#336aff", fill: "none" }} />
                            <SparklinesSpots size={4} style={{ stroke: "#336aff", strokeWidth: 3, fill: "white" }} />
                          </Sparklines>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-l-15">Total POD</td>
                        <td>13,203 <span className="text-success"><i className="fa fa-arrow-up"></i></span></td>
                        <td className="p-r-15">
                          <Sparklines data={[30, 40, 10, 30, 90, 70, 80, 60]}>
                            <SparklinesBars style={{ fill: "#41c3f9", fillOpacity: ".25" }} />
                            <SparklinesLine style={{ stroke: "#41c3f9", fill: "none" }} />
                          </Sparklines>

                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </PanelBody>
            </Panel>

          </div>
        </div>
      </div>
    )
  }
};

export default Home;