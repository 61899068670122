import React from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom';
import { Panel, PanelBody, PanelFooter } from '../../components/panel/panel.jsx';
import { PageSettings } from '../../config/page-settings.js';
import 'react-tabs/style/react-tabs.css';
import { NumericFormat } from 'react-number-format'; 
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';

class Receiving extends React.Component {

  static contextType = PageSettings;
  state = {
    uuid: null,
    receivedPackages: [],
    packageNum: null,
    shipmentNum: null,
    waybillNum: null,
    hubDestination: "000000",
    declareWeight: 0,
    declareLength: 0,
    declareWidth: 0,
    declareHeight: 0
  }

  searchPackage = async () => {
    if(!this.state.packageNum || this.state.packageNum.trim === "") {
      await NotificationManager.error(`Please input Reference Number!!!`);
      return;
    }

    await axios.post(`${window.APP_API}/api/v1/receiving/validate`,
      { reffNum: this.state.packageNum }
    )
      .then(res => {

        this.setState({
          uuid: res.data.uuid,
          declareWeight: res.data.declareWeight,
          declareLength: res.data.declareLength,
          declareWidth: res.data.declareWidth,
          declareHeight: res.data.declareHeight,
          actualWeight: res.data.actualWeight,
          actualLength: res.data.actualLength,
          actualWidth: res.data.actualWidth,
          actualHeight: res.data.actualHeight,
          waybillNum: res.data.waybillNum,
          shipmentNum: res.data.packageNum,
          hubDestination: res.data.hubDestination,
        })

      }).catch(async (err) => {
        await NotificationManager.warning(`Failed  ${err.response.data.message}`);
      })

  }

  confirmActual = async () => {
    if(!this.state.packageNum || this.state.packageNum.trim === "") {
      await NotificationManager.warning(`Please input Reference Number!!!`);
      return;
    }
    await axios.post(`${window.APP_API}/api/v1/receiving/protection`,
      {
        uuid: this.state.uuid,
        actualWeight: this.state.actualWeight,
        actualLength: this.state.actualLength,
        actualWidth: this.state.actualWidth,
        actualHeight: this.state.actualHeight,
      }
    )
      .then(async (res)  => { 
        const newRecord = {
          waybillNum: this.state.waybillNum,
          packageNum: this.state.shipmentNum,
          destination: this.state.hubDestination,
          weight: this.state.actualWeight,
          dimension: `${this.state.actualLength} x ${this.state.actualWidth} x ${this.state.actualHeight}`
        } 

        this.setState( prevState => ( {
          receivedPackages: [...prevState.receivedPackages, newRecord]
        }))

         this.setState({
          packageNum: "",
          shipmentNum: null,
          waybillNum: null,
          uuid: null,
          declareWeight: 0,
          declareLength: 0,
          declareWidth: 0,
          declareHeight: 0,
          actualWeight: 0,
          actualLength: 0,
          actualWidth: 0,
          actualHeight: 0, 
          hubDestination: "000000"

        })
        await NotificationManager.success(res.data.message);
      }).catch( async(err) => {
        await NotificationManager.error(`Failed  ${err.response.data.message}`);
      })
  }

  _handleKeyDown = (e) => {
    if (e.keyCode === 13) { // if enter or tab
      this.searchPackage()
    }
  }

  _handleOnChangeInputText = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  async componentDidMount() {

    this.context.handleSetPageSidebarMinified(true);
    this.context.handleSetPageFooter(false);

  }

  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item"><Link to="/">Home</Link></li>
          <li className="breadcrumb-item active">Active Page</li>
        </ol>
        <h1 className="page-header">Receiving <small>header small text goes here...</small></h1>
        <div className="row" >
          <div className="col-sm-6 col-md-6">

            <div className="row mb-3" >
              <div className="col-md-7">
                <label className="form-label">REFERENCE NO</label>
                <div className="input-group">
                  <input className="form-control form-control-lg" type="text" name="packageNum" onChange={this._handleOnChangeInputText} onKeyDown={this._handleKeyDown} value={this.state.packageNum} placeholder="Shipment / Package No" required="" />
                  <div className="input-group-text m-0 p-0">
                    <button className="btn btn-lg m-0 p-0" onClick={this.searchPackage}><span className="fas fa-search text-primary fs-2"></span></button>

                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className="col-sm-6 col-md-6">
            <div className="row mb-3">
              <div className="col-md-8">
                <label className="form-label">PACKAGE NO / WAYBILL/ HUB</label>
                <div className="input-group input-group-sm mb-3">
                  <input className="form-control form-control-sm" type="text" value={this.state.shipmentNum} disabled={true} readOnly />
                  <input className="form-control form-control-sm" type="text" value={this.state.waybillNum} disabled={true} readOnly />
                  <span className="input-group-text text-danger">{this.state.hubDestination}</span>
                </div>
              </div>
              <div className="col-md-4">
                <label className="form-label">WEIGHT (KG)</label>
                <div className="input-group input-group-sm text-right">
                  <span className="input-group-text text-danger f-w-700 width-70 text-right">{this.state.declareWeight}</span>
                  <NumericFormat name="actualWeight" className="form-control form-control-sm text-right"
                    value={this.state.actualWeight} thousandSeparator="," onChange={this._handleOnChangeInputText}
                    allowNegative={false} decimalScale={2}
                    isAllowed={(values) => {
                      const { floatValue } = values;
                      return floatValue <= 10000;
                    }} />
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-4">
                <label className="form-label">LENGTH (CM)</label>
                <div className="input-group input-group-sm">
                  <span className="input-group-text text-danger f-w-700 width-70 text-right">{this.state.declareLength}</span>
                  <NumericFormat name="actualLength" className="form-control form-control-sm text-right"
                    value={this.state.actualLength} thousandSeparator="," onChange={this._handleOnChangeInputText} allowNegative={false} decimalScale={0}
                    isAllowed={(values) => {
                      const { floatValue } = values;
                      return floatValue <= 10000;
                    }} />
                </div>
              </div>
              <div className="col-md-4">
                <label className="form-label">WIDTH (CM)</label>
                <div className="input-group input-group-sm">
                  <span className="input-group-text text-danger f-w-700 width-70 text-right">{this.state.declareWidth}</span>
                  <NumericFormat name="actualWidth" className="form-control form-control-sm text-right"
                    value={this.state.actualWidth} thousandSeparator="," onChange={this._handleOnChangeInputText} allowNegative={false} decimalScale={0}
                    isAllowed={(values) => {
                      const { floatValue } = values;
                      return floatValue <= 10000;
                    }} />
                </div>
              </div>
              <div className="col-md-4">
                <label className="form-label">HEIGHT (CM)</label>
                <div className="input-group input-group-sm">
                  <span className="input-group-text text-danger f-w-700 width-70 text-right">{this.state.declareHeight}</span>
                  <NumericFormat name="actualHeight" className="form-control form-control-sm text-right"
                    value={this.state.actualHeight} thousandSeparator="," onChange={this._handleOnChangeInputText} allowNegative={false} decimalScale={0}
                    isAllowed={(values) => {
                      const { floatValue } = values;
                      return floatValue <= 10000;
                    }} />
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-5">
                <button className="form-control btn btn-primary" name="receive" type="button" onClick={this.confirmActual}>RECEIVE (F7)</button>
              </div>
              <div className="col-md-3">
                <button className="form-control btn btn-warning ml-4" name="reset" type="reset">RESET</button>
              </div>
            </div>
          </div>
        </div>
        <Panel>
          <PanelBody>
            <div className="row">
              <div className="col-sm-6 col-md-6 pl-2">
                <table className="table table-hover table-striped table-sm tbl-receiving" id="tbl-receiving">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Package</th>
                      <th>Waybill</th>
                      <th scope="col">Destination</th>
                      <th scope="col">Weight</th>
                      <th scope="col">Dimension</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.receivedPackages.map( (rec, index) => {
                      return (
                        <tr key="{idx}`">
                          <td>{index+1}</td>
                          <td>{rec.packageNum}</td> 
                          <td>{rec.waybillNum}</td> 
                          <td className="text-center">{rec.destintion}</td>
                          <td className="text-right">{rec.weight}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                          <td className="ml-10">{rec.dimension}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </PanelBody>
          <PanelFooter className=" bg-grey-transparent-1">
            <strong>CHILDREN</strong>
            <p>
               
            </p>
          </PanelFooter>
        </Panel>
        <NotificationContainer />
      </div>
    )
  }

}

export default Receiving;