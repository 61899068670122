import React from 'react';
import axios from 'axios'
import { Link, useParams } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { PageSettings } from '../../config/page-settings.js';
import moment from "moment"
import Select from 'react-select';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { isEmpty } from "../../helper/helper.js"

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class Manifest extends React.Component {

  static contextType = PageSettings;

  state = {
    currentPage: 1,
    totalRows: 0,
    hubs: [],
    transporter: [],
    data: [],
    manifestDate: moment().format("YYYY-MM-DD")
  }

  _handleOnChangeInputText = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  _handleKeyDown = async (e) => {
    if (e.target.name === "packageNum" && e.keyCode === 13) { // if enter or tab
      await this.addPackage();
      this.setState({ packageNum: "" })

    }
    if (e.target.name === "manifestNum" && e.keyCode === 13) {
      alert(`Enter ${e.target.name}`)
    }
  }

  _handleOnBlur = async (e) => {
    alert(e.target.name)

  }

  async componentDidMount() {
    this.setState({
      rowsPerPage: this.context.defaultRowsPerPage
    })

    await axios.post(`${window.APP_API}/api/v1/ui/fmanifest`)
      .then(async (res) => {
        this.setState({
          hubs: res.data.hubs ?? [],
          transporter: res.data.transporter ?? [],
        })
      }).catch(err => {

      })

    if (!isEmpty(this.props.params.uuid)) {
      this.setState({ uuid: this.props.params.uuid })
    }
  }

  addPackage = async (e) => {
    const params = {
      manifestDate: this.state.manifestDate,
      origin: this.state.origin,
      destination: this.state.destination,
      transporter: this.state.transporter,
      vehicleNo: this.state.vehicleNo,
      driverName: this.state.driverName,
      driverPhone: this.state.driverPhone,
      remarks: this.state.remarks,
    }


    await axios.post(`${window.APP_API}/api/v1/manifest/add-detail`,
      params)
      .then(async (res) => {
        this.setState(prevState => {
          data: prevState.data.unshift(res.data)
        })

      }).catch(err => {
      })

  }


  save = async (e) => {

    let url = {}
    if (isEmpty(this.state.uuid)) {
      console.log(`isEmpty: ${this.state.uuid}`)
      url = `${window.APP_API}/api/v1/manifest/create`
    } else {
      url = `${window.APP_API}/api/v1/manifest/${this.state.uuid}/update`
    }
    const params = {}

    await axios.post(url,
      params)
      .then(async (res) => {

        await NotificationManager.success(res.data.message);
        if (this.state.uuid === undefined) {
          setTimeout(() => {
            window.location.replace(`/manifest/${res.data.uuid}/view`);
          }, 2000)
        }

      }).catch(err => {
      })
  }

  print = async (e) => {
    await NotificationManager.success(`Prin Manifest`);
    window.open(`${window.APP_API}/api/v1/manifest/${this.state.uuid}/print`, "_blank", "noreferrer");
  }

  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item"><Link to="/manifest"> <span className="fa fa-angle-double-left"></span> Manifest List</Link></li>
        </ol>
        <h1 className="page-header">{!this.state.uuid ? "New" : "Update"} Manifest <small>Maintenance Data {this.state.packageNum}</small></h1>
        <Panel>
          <PanelHeader className="bg-orange-transparent-9 text-right p-4 p-r-0">
            <div className="col-sm-12 p-r-0">
              <div className="btn-group btn-group-sm p-r-0">
                <button className="btn btn-sm btn-primary f-s-11 text-center" onClick={this.save} disabled={(parseInt(this.state.shipmentStatus) > 100)}><i className="fa fa-save"></i> {!this.state.uuid ? "Create" : "Update"}</button>
                {1 !== 3 ? (<UncontrolledButtonDropdown>
                  <DropdownToggle className={"btn btn-sm"} color="primary" caret></DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem className="dropdown-item f-s-11" onClick={this.confirm}><i className="fa fa-thumbs-up"></i> Confirm</DropdownItem>
                    {!this.state.uuid ? <DropdownItem className="dropdown-item f-s-11" onClick={this.print}><i className="fa fa-print"></i> Print Manifest</DropdownItem> : null}
                  </DropdownMenu>
                </UncontrolledButtonDropdown>) : null}
              </div>
              <button className="btn btn-sm btn-grey f-s-11 text-center ml-3" onClick={() => { }}><i className="fa fa-minus-circle"></i> Cancel</button>

            </div>
          </PanelHeader>
          <PanelBody>
            <div className='row'>

              <div className='col-sm-8'>
                <div className="form-group row m-b-0">
                  <label className="col-sm-3 col-form-label">Manifest Num</label>
                  <div className="col-sm-4">
                    <input type="text" name="manifestNum" value={this.state.cityCode} className="form-control form-control-sm" disabled={true} />
                  </div>
                  <div className="col-sm-4 form-inline">
                    <div className="form-inline">
                      Date : <input type="date" name="manifestDate" value={this.state.manifestDate} className="form-control form-control-sm" disabled={true} />
                    </div>

                  </div>
                </div>
                <div className="form-group row m-b-3">
                  <label className="col-sm-3 col-form-label">Origin / Destination</label>
                  <div className="col-sm-4">
                    <Select name="provinceCode" placeholder={"Origin"}
                      value={this.state.hubs.filter(({ value }) => { return value === this.state.provinceCode; })}
                      options={this.state.hubs}
                      onChange={this.onChangeProvince}
                      isClearable={true} isSearchable={true} />
                  </div>
                  <div className="col-sm-4">
                    <Select name="provinceCode" placeholder={"Destination"}
                      value={this.state.hubs.filter(({ value }) => { return value === this.state.provinceCode; })}
                      options={this.state.hubs}
                      onChange={this.onChangeProvince}
                      isClearable={true} isSearchable={true} />
                  </div>
                </div>

                <div className="form-group row m-b-3">
                  <label className="col-sm-3 col-form-label">Transporter</label>
                  <div className="col-sm-8">
                    <Select name="transporterCode" placeholder={"Transporter"}
                      value={this.state.hubs.filter(({ value }) => { return value === this.state.provinceCode; })}
                      options={this.state.hubs}
                      onChange={this.onChangeProvince}
                      isClearable={true} isSearchable={true} />
                  </div>
                </div>

                <div className="form-group row m-b-1">
                  <label className="col-sm-3 col-form-label">Vehicle / Driver</label>
                  <div className="col-sm-2">
                    <input type="text" name="plateNo" value={this.state.cityName} onChange={this._handleOnChangeInputText} placeholder="Vehicle / Plate" className="form-control form-control-sm" maxLength={9} />
                  </div>
                  <div className="col-sm-3">
                    <input type="text" name="driverName" value={this.state.cityName} onChange={this._handleOnChangeInputText} placeholder="Driver Name" className="form-control form-control-sm" />
                  </div>
                  <div className="col-sm-3">
                    <input type="text" name="driverPhone" value={this.state.cityName} onChange={this._handleOnChangeInputText} placeholder="Driver Phone" className="form-control form-control-sm" />
                  </div>
                </div>

                <div className="form-group row m-b-3">
                  <label className="col-sm-3 col-form-label">Remarks</label>
                  <div className="col-sm-8">
                    <textarea name="shipperAddress"
                      onChange={this._handleOnChangeInputText} value={this.state.shipperAddress}
                      className='form-control form-control-sm' placeholder="Remarks / Note"></textarea>
                  </div>
                </div>
              </div>

              {!isEmpty(this.state.uuid) ?
                <div className='col-sm-4'>
                  <div className="row mb-3" >
                    <div className="col-md-7">
                      <label className="form-label">PACKAGE / BAGGING</label>
                      <div className="input-group">
                        <input className="form-control form-control-lg" type="text" name="packageNum" autoFocus onChange={this._handleOnChangeInputText} onKeyDown={this._handleKeyDown} value={this.state.packageNum ?? ""} placeholder="Package / Bagging" required="" />
                        <div className="input-group-text m-0 p-0">
                          <button className="btn btn-lg m-0 p-0" onClick={this.searchPackage}><span className="fas fa-search text-primary fs-2"></span></button>

                        </div>
                      </div>
                    </div>
                  </div>
                </div> : null
              }

            </div>
          </PanelBody>
        </Panel>
        {!isEmpty(this.state.uuid) ?
          <Panel>
            <PanelBody>
              <div className='row'>
                <div className='col-sm-8'>
                  <div className="table-responsive table-striped bg-white">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>Bagging</th>
                          <th>Weight</th>
                          <th>Volume</th>
                          <th>Destination</th>
                          <th>&nbsp;</th>
                        </tr>

                      </thead>
                      <tbody>
                        {this.state.data.map((val, key) => {
                          return (
                            <tr key={val.id}>
                              <td>{((this.state.currentPage - 1) * this.state.rowsPerPage) + (key + 1)}.</td>
                              <td>{val.package}</td>
                              <td>{val.weight}</td>
                              <td>{val.volume}</td>
                              <td>{val.destination}</td>
                              <td><button className="btn btn-xs btn-danger m-0"><i className="fa fa-sm fa-trash"></i></button></td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </PanelBody>
          </Panel> : null}
        <NotificationContainer />
      </div>
    )
  }
}


export default withParams(Manifest); 