import React from 'react';
import Home from "../pages/Home.js"
import Shipment from '../pages/shipment/Shipment.js';
import ShipmentList from '../pages/shipment/ShipmentList.js';
import ShipmentBulkyUpload from "../pages/shipment/ShipmentBulkyUpload.js"
import Receiving from '../pages/receiving/Receiving.js';
import Bagging from '../pages/bagging/Bagging.js';
import UnBagging from '../pages/bagging/UnBagging.js';
import ProvinceList from '../pages/province/ProvinceList.js'
import Province from '../pages/province/Province.js'
import CityList from '../pages/city/CityList.js'
import City from '../pages/city/City.js'
import DistrictList from '../pages/district/DistrictList.js'
import District from '../pages/district/District.js'
import Customer from '../pages/customer/Customer.js'
import CustomerList from '../pages/customer/CustomerList.js'
import AccountList from '../pages/account/AccountList.js';
import Account from '../pages/account/Account.js';
import Courier from '../pages/courier/Courier.js';
import CourierList from '../pages/courier/CourierList.js';
import Manifest from '../pages/manifest/Manifest.js';
import ManifestList from '../pages/manifest/ManifestList.js';
import RunSheet from '../pages/runsheet/RunSheet.js';
import RunSheetPOD from '../pages/runsheet/RunSheetPOD.js'; 
import RunSheetList from '../pages/runsheet/RunSheetList.js';

const routes = [
  {
    path: '/',
    exact: true,
    title: 'Home',
    component: () => <Home />
  },
  {
    path: '/shipment',
    exact: true,
    title: "Shipment List",
    component: () => <ShipmentList />
  },
  {
    path: '/shipment/create',
    exact: true,
    title: "Create Shipment",
    component: () => <Shipment />
  },
  {
    path: '/shipment/:uuid/view',
    exact: true,
    title: "View Shipment",
    component: () => <Shipment />
  },
  {
    path: '/shipment/bulky-upload',
    exact: true,
    title: "Shipment Bulky Upload",
    component: () => <ShipmentBulkyUpload />
  },
  {
    path: '/receiving',
    exact: true,
    title: 'Receiving',
    component: () => <Receiving />
  },
  {
    path: '/bagging',
    exact: true,
    title: 'Bagging',
    component: () => <Bagging />
  },
  {
    path: '/unbagging',
    exact: true,
    title: 'UnBagging',
    component: () => <UnBagging />
  },
  {
    path: '/manifest',
    exact: true,
    title: 'Manifest List',
    component: () => <ManifestList />
  }, {
    path: '/manifest/create',
    exact: true,
    title: 'Create New Manifest',
    component: () => <Manifest />
  },{
    path: '/manifest/:uuid/view',
    exact: true,
    title: 'Update  Manifest',
    component: () => <Manifest />
  },
  {
    path: '/runsheet',
    exact: true,
    title: 'Run Sheet List',
    component: () => <RunSheetList />
  }, {
    path: '/runsheet/create',
    exact: true,
    title: 'Create New Run Sheet',
    component: () => <RunSheet />
  },{
    path: '/runsheet/:uuid/view',
    exact: true,
    title: 'Update / View Run Sheet',
    component: () => <RunSheet />
  }, {
    path: '/runsheet/pod',
    exact: true,
    title: 'Run Sheet POD',
    component: () => <RunSheetPOD />
  },
  {
    path: '/master/province',
    exact: true,
    title: 'Province List',
    component: () => <ProvinceList />
  },
  {
    path: '/master/province/create',
    exact: true,
    title: 'Province',
    component: () => <Province />
  },
  {
    path: '/master/province/view/:uuid',
    exact: true,
    title: 'Province',
    component: () => <Province />
  },
  {
    path: '/master/city',
    exact: true,
    title: 'City List',
    component: () => <CityList />
  },
  {
    path: '/master/city/create',
    exact: true,
    title: 'Create City',
    component: () => <City />
  },
  {
    path: '/master/city/view/:uuid',
    exact: true,
    title: 'Update City',
    component: () => <City />
  },
  {
    path: '/master/district',
    exact: true,
    title: 'District List',
    component: () => <DistrictList />
  },
  {
    path: '/master/district/create',
    exact: true,
    title: 'Create District',
    component: () => <District />
  },
  {
    path: '/master/district/view/:uuid',
    exact: true,
    title: 'Update District',
    component: () => <District />
  },
  {
    path: '/master/account',
    exact: true,
    title: 'Account List',
    component: () => <AccountList />
  },
  {
    path: '/master/account/create',
    exact: true,
    title: 'Create New Account',
    component: () => <Account />
  },
  {
    path: '/master/account/:uuid/view',
    exact: true,
    title: 'Update Account',
    component: () => <Account />
  },
  {
    path: '/master/customer',
    exact: true,
    title: 'Customer List',
    component: () => <CustomerList />
  },
  {
    path: '/master/customer/create',
    exact: true,
    title: 'Update Customer',
    component: () => <Customer />
  },
  {
    path: '/master/customer/:uuid/view',
    exact: true,
    title: 'Update Customer',
    component: () => <Customer />
  },
  {
    path: '/master/courier',
    exact: true,
    title: 'Courier List',
    component: () => <CourierList />
  },
  {
    path: '/master/courier/create',
    exact: true,
    title: 'Create New Customer',
    component: () => <Courier />
  },
  {
    path: '/master/courier/:uuid/view',
    exact: true,
    title: 'Update Customer',
    component: () => <Courier />
  }, 
];


export default routes;