import React from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody, PanelFooter } from '../../components/panel/panel.jsx';
import { PageSettings } from '../../config/page-settings.js';
import Select from 'react-select';
import Pagination from '../../components/pagination/pagination.jsx'; 
import moment from "moment"

class DistrictList extends React.Component {

  static contextType = PageSettings;
  // static defaultRowsPerPage = this.context.defaultRowsPerPage

  state = {
    currentPage: 1,
    rowsPerPage: 10,
    totalRows: 0,
    data: [],
    provinces: [],
    datax: [],
  }

  _handleOnChangeInputText = async (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  _handleOnChangeSelect = async (e) => {
    await this.setState({ [e.target.name]: e.target.value })
    await this.refreshTable()
  }

  _handleKeyDown = (e) => {
    if (e.keyCode === 13) { // if enter or tab
      this.refreshTable()
    }
  }

  onChangeProvince = (option, e) => {
    if (e.action === "select-option") {
      this.setState({ provinceCode: option.value, provinceName: option.label })
    } else if (e.action === "clear") {
      this.setState({ provinceCode: null, provinceName: null })
    }
  }

  async componentDidMount() {
    const rowsPerPage = this.context.defaultRowsPerPage
    await this.setState({ rowsPerPage: rowsPerPage })

    this.context.handleSetPageSidebarMinified(true);
    this.context.handleSetPageFooter(false);

    await axios.post(`${window.APP_API}/api/v1/ui/province`)
      .then(async (res) => {
        const data = res.data;
        this.setState({ provinces: data.provinces ?? [] })
      }).catch(err => {

      }) 

      await this.refreshTable() 
  }

  onChangePageHandler = async (page) => {
    await this.setState({ currentPage: page })
    await this.refreshTable()
  }

  onChangePageSelectHandler = async (e) => {
    await this.setState({ [e.target.name]: e.target.value })
    await this.refreshTable()
  }

  refreshTable = async () => {
    const params = {}

    if (this.state.provinceCode) params.provinceCode = this.state.provinceCode
    if (this.state.cityCode) params.cityCode = this.state.cityCode
    if (this.state.districtName) params.districtName = this.state.districtName

    params.page = this.state.currentPage;
    params.size = this.state.rowsPerPage;

    await axios.get(`${window.APP_API}/api/v1/district`, {
      params: params
    }).then(async (res) => {
      await this.setState({ data: res.data.data, totalRows: res.data.total })
    }).catch(err => {

    })

  }
 

  render() {

    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item"><Link to="/">Home</Link></li>
          <li className="breadcrumb-item active">Active Page</li>
        </ol>
        <h1 className="page-header">District <small>Maintenance District Master</small></h1>

        <Panel>
          <PanelHeader btnToggle>District Search</PanelHeader>
          <PanelBody>
            <div className="row" >
              <div className='col-sm-6'>
                <div className="form-group row m-b-3">
                  <label className="col-sm-3 col-form-label">Province</label>
                  <div className="col-sm-8">
                    <Select name="provinceCode" placeholder={"Province"}
                      value={this.state.provinces.filter(({ value }) => { return value === this.state.provinceCode; })}
                      options={this.state.provinces}
                      onChange={this.onChangeProvince}
                      isClearable={true} isSearchable={true} />
                  </div>
                </div>
                <div className="form-group row m-b-1">
                  <label className="col-sm-3 col-form-label">District Name</label>
                  <div className="col-sm-8">
                    <input type="text" name="districtName" value={this.state.districtName} onChange={this._handleOnChangeInputText} onKeyDown={this._handleKeyDown} className="form-control form-control-sm" />
                  </div>
                </div>
                <div className="form-group row m-b-1">
                  <label className="col-sm-3 col-form-label">&nbsp;</label>
                  <div className="col-sm-8">
                    <button type="button" className="btn btn-sm btn-primary" onClick={this.refreshTable}><span className="fa fa-sm fa-search"></span> Search</button>
                    <Link to="/master/district/create" className="btn btn-sm btn-grey ml-5"><span className="fa fa-plus-circle"></span> New</Link>
                  </div>
                </div>
              </div>
            </div>
          </PanelBody>
        </Panel>

        <Panel>
          <PanelBody>
            <div className='row'>
              <div className='col-sm-12'>
                <div className="table-responsive table-striped bg-white">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Code</th>
                        <th>Name</th>
                        <th>Province</th>
                        <th>City</th>
                        <th>Active</th>
                        <th>Updated By</th>
                        <th>Last Update</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data.map((val, key) => {
                        return (
                          <tr key={val.uuid}>
                            <td>{((this.state.currentPage - 1) * this.state.rowsPerPage) + (key + 1)}.</td>
                            <td><Link to={`/master/district/view/${val.uuid}`}><span className="fas fa-pencil-alt fa-fw"></span></Link> {val.district_code}</td>
                            <td>{val.district_name}</td>
                            <td>{val.province.prov_name}</td>
                            <td>{val.city.city_name}</td>
                            <td>{val.active ? (
                              <span className="fa fa-check-circle fa-lg text-green" title="nom"></span>
                            ) : <span className="fa fa-check-circle fa-lg text-grey"></span>}</td>
                            <td>{val.updated_by}</td>
                            <td>{moment(val.updated_at).format("YYYY-MM-DD HH:mm:ss")}</td>
                            <td><button className="btn btn-xs btn-danger m-0"><i className="fa fa-sm fa-trash"></i></button></td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </PanelBody>
          <PanelFooter>
            <div className='row'>
              <div className='col-sm-12'>
              <Pagination
                  className="pagination"
                  currentPage={this.state.currentPage} 
                  totalRows={this.state.totalRows}
                  pageSize={this.state.rowsPerPage ?? this.context.defaultRowsPerPage}
                  onPageChange={this.onChangePageHandler}
                  selectedPageHandler={this.onChangePageSelectHandler}
                />
              </div> 
            </div>
          </PanelFooter>
        </Panel>

      </div>
    )
  }
}



export default DistrictList;