import React from 'react';
import axios from 'axios' 
import { Link, Redirect } from 'react-router-dom'; 

import { PageSettings } from './../config/page-settings.js';
import { isAuthenticated } from  "./../helper/helper.js"
import { NotificationContainer, NotificationManager } from 'react-notifications';
 
class Login extends React.Component {

	static contextType = PageSettings;

	state = {
		username: null,
		password: null,
	}
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);

	}

	componentDidMount() {
		this.context.handleSetPageSidebar(false);
		this.context.handleSetPageHeader(false);
		this.context.handleSetBodyWhiteBg(false); 
	}

	componentWillUnmount() {
		this.context.handleSetPageSidebar(true);
		this.context.handleSetPageHeader(true);
		this.context.handleSetBodyWhiteBg(false);
	}

	async handleSubmit(event) {
		event.preventDefault();

		await axios.post(`${window.APP_API}/api/v1/auth/login`, {
			username: this.state.username,
			password: this.state.password
		}).then(async (res) => { 
			localStorage.setItem("token", res.data.token) 
			this.context.handleAuthentication({isLoggedIn: true})
			
		}).catch( async (err) => {
			await NotificationManager.error(`${err.response.data.message}`); 
		})

	}

	render() {  
		if(isAuthenticated()) { 
			this.props.history.push('/') 
		}  
		return (
			<div className="login login-with-news-feed">
				<div className="news-feed">
					<div className="news-image" style={{ backgroundImage: 'url(/assets/login-bg-11.jpg)' }}></div>
					<div className="news-caption">
						<h4 className="caption-title"><b>Color</b> Admin App</h4>
						<p>
							{/* Download the Color Admin app for iPhone®, iPad®, and Android™. Lorem ipsum dolor sit amet, consectetur adipiscing elit. */}
						</p>
					</div>
				</div>
				<div className="right-content">
					<div className="login-header">
						<div className="brand">
							<span className="logo"></span> <b>Xpress</b>
							<small>manage your delivery</small>
						</div>
						<div className="icon">
							<i className="fa fa-sign-in"></i>
						</div>
					</div>
					<div className="login-content">
						<form className="margin-bottom-0" onSubmit={this.handleSubmit} autoComplete="false">
							<div className="form-group m-b-15">
								<input type="text" onChange={(e) => {
									this.setState(state => ({
										username: e.target.value
									}))
								}} className="form-control form-control-lg" placeholder="Email Address" required />
							</div>
							<div className="form-group m-b-15">
								<input type="password" onChange={(e) => {
									this.setState(state => ({
										password: e.target.value
									}))
								}} className="form-control form-control-lg" placeholder="Password" required autoComplete="false" />
							</div>
							<div className="checkbox checkbox-css m-b-30">
								<input type="checkbox" id="remember_me_checkbox" value="" />
								<label htmlFor="remember_me_checkbox">
									Remember Me
								</label>
							</div>
							<div className="login-buttons">
								<button type="submit" className="btn btn-success btn-block btn-lg">Sign me in</button>
							</div>
							{/* <div className="m-t-20 m-b-40 p-b-40 text-inverse">
								Not a member yet? Click <Link to="/user/register-v3" className="text-success">here</Link> to register.
							</div> */}
							<hr />
							<p className="text-center text-grey-darker">
								&copy; Color Admin All Right Reserved 2020
							</p>
						</form>
					</div>
				</div>
				<NotificationContainer />
			</div>
		)
	}
}

export default  Login ;