import React from "react";
import { Link, useParams } from 'react-router-dom';
import Switch from "react-switch";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import axios from 'axios'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Panel, PanelBody, PanelHeader } from '../../components/panel/panel.jsx';
import { PageSettings } from '../../config/page-settings.js';
import Select from 'react-select';
import moment from "moment"
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/id';
import { isEmpty } from "../../helper/helper.js"
import Swal from 'sweetalert2'

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class Account extends React.Component {

  static contextType = PageSettings;

  state = {
    customers: [],
    provinces: [],
    cities: [],
    districts: [],
    subdistricts: [],
    postalcodes: [],
    provinceCode: null,
    provinceName: null,
    customerCode: "C000000",
    active: true,
    couriers: [],
    accountCouriers: [],
    courierSetup: {
      id: 0,
      courierCode: null,
      attributes: null
    },
    selectedCodeCourierSetup: null,
    selectedIdCourierSetup: 0,
    selectedAttributesCourierSetup: {}, 
    jsonIsValid: true

  }

  addCourierSetupRow = () => {
    const isNewRow = this.state.accountCouriers.filter((item) => { return item.id === 0 })

    if (isNewRow.length > 0) {
      return;
    }
    const rows = this.state.accountCouriers
    rows.push({ id: 0, courier_code: "", attributes: {} })

    this.setState({
      accountCouriers: rows,
      selectedCodeCourierSetup: null,
      selectedIdCourierSetup: 0,
      selectedAttributesCourierSetup: {},

    })
  }

  saveCourierSetup = async () => {
    if (!this.state.jsonIsValid) {
      await NotificationManager.warning(`JSON Params is not valid !!`);
      return;
    }

    if (isEmpty(this.state.selectedCodeCourierSetup)) {
      await NotificationManager.warning(`Courier is not allow Empty`);
      return;
    }

    const params = {
      courierCode: this.state.selectedCodeCourierSetup,
      attributes: this.state.selectedAttributesCourierSetup
    }

    const url = `${window.APP_API}/api/v1/account/${this.state.uuid}/courier-setup/${this.state.selectedIdCourierSetup}`

    await axios.post(url,
      params
    ).then(async (res) => {
      if (res.data.couriers) {
        this.setState({ accountCouriers: res.data.couriers, selectedIdCourierSetup: res.data.id })
      }
      await NotificationManager.success(`${res.data.message}`);
    }).catch(async (err) => {
      await NotificationManager.error(`Failed  ${err.response.data.message}`);
    })

  }

  deleteCourierSetup = val => async () => {
    Swal.fire({
      text: `You want to delete record Courier Setup ${this.state.accName} / ${val.courier_code} ?`,
      icon: 'warning',
      confirmButtonText: 'Yes',
      showCloseButton: true,
      showCancelButton: true
    }).then(async (result) => {

      if (result.isConfirmed) {
        const url = `${window.APP_API}/api/v1/account/${this.state.uuid}/courier-setup/${val.id}/delete`

        await axios.post(url,
          { accName: this.state.accName, courierCode: val.courier_code }
        ).then(async (res) => {

          const couriers = this.state.accountCouriers
          const idx = couriers.map(e => e.id).indexOf(val.id)
          couriers.splice(idx, 1);
          this.setState({
            accountCouriers: couriers,
            selectedCodeCourierSetup: null,
            selectedIdCourierSetup: 0,
            selectedAttributesCourierSetup: {}
          })
        }).catch(async (err) => {
          await NotificationManager.error(`Failed  ${err.response.data.message}`);
        })
      }
    })

  }

  _handleOnChangeInputText = async (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  onChangeJsonFormat = async (e) => {
    this.setState({
      jsonIsValid: (e.jsObject === undefined) ? false : true,
      selectedAttributesCourierSetup: e.jsObject ?? {}
    }) 
  }

  _onChangeSelectTableCourierSetup = id => async (e) => {
    const rows = this.state.accountCouriers
    rows.find((row) => row.id === id).courier_code = e.target.value

    this.setState({ accountCouriers: rows })
    this.setState({
      selectedCodeCourierSetup: e.target.value,
      selectedIdCourierSetup: id,
      selectedAttributesCourierSetup: rows.find((row) => row.id === id).attributes ?? {},
    })
  }

  _handleOnChangeEditCourierSetup = async (val) => {

    this.setState({
      selectedCodeCourierSetup: val.courier_code,
      selectedIdCourierSetup: val.id,
      selectedAttributesCourierSetup: val.attributes ?? {},
    })

  }

  _handleOnChangeSwitch = async (c, e, id) => {
    this.setState({ [id]: c })
  }

  onChangeReactSelect = async (option, e) => {
    if (e.action === "select-option") {
      this.setState({ [e.name]: option.value })
    } else if (e.action === "clear") {
      this.setState({ [e.name]: null })
    }
  }

  onChangeProvince = async (option, e) => {
    if (e.action === "select-option") {
      this.setState({ provinceCode: option.value, provinceName: option.label })
      await axios.post(`${window.APP_API}/api/v1/ui/city`, {
        prov_code: option.value
      }).then(res => {
        const cities = res.data;
        this.setState({ cities: cities })
      }).catch(err => {

      })
    } else if (e.action === "clear") {
      this.setState({
        provinceCode: null,
        provinceName: null,
        cityCode: null,
        cityName: null,
        districtCode: null,
        districtName: null,
        subDistrictCode: null,
        subDistrictName: null,
        postalCode: null,
        cities: [],
        districts: [],
        subDistricts: [],
        postalCodes: [],
      })
    }
  }

  onChangeCity = async (option, e) => {
    if (e.action === "select-option") {
      this.setState({ cityCode: option.value, cityName: option.label })
      await axios.post(`${window.APP_API}/api/v1/ui/district`, {
        city_code: option.value
      }).then(res => {
        const districts = res.data;
        this.setState({ districts: districts })
      }).catch(err => {

      })
    } else if (e.action === "clear") {
      this.setState({
        cityCode: null,
        cityName: null,
        districtCode: null,
        districtName: null,
        subDistrictCode: null,
        subDistrictName: null,
        postalCode: null,
        districts: [],
        subDistricts: [],
        postalCodes: [],
      })
    }
  }

  onChangeDistrict = async (option, e) => {
    if (e.action === "select-option") {
      this.setState({ districtCode: option.value, districtName: option.label })

      await axios.post(`${window.APP_API}/api/v1/ui/subdistrict`, {
        district_code: option.value
      }).then(res => {
        const subdistricts = res.data;
        this.setState({ subdistricts: subdistricts })
      }).catch(err => {
      })

      await axios.post(`${window.APP_API}/api/v1/ui/postalcode`, {
        district_code: option.value
      }).then(async (res) => {
        const postalcodes = res.data;
        this.setState({ postalcodes: postalcodes })
      }).catch(err => {
      })

    } else if (e.action === "clear") {
      this.setState({
        cityCode: null,
        cityName: null,
        districtCode: null,
        districtName: null,
        subDistrictCode: null,
        subDistrictName: null,
        postalCode: null,
        districts: [],
        subDistricts: [],
        postalCodes: [],
      })
    }
  }

  onChangeSubDistrict = async (option, e) => {
    if (e.action === "select-option") {
      this.setState({ subDistrictCode: option.value, subDistrictName: option.label })
    } else if (e.action === "clear") {
      this.setState({
        subDistrictCode: null,
        subDistrictName: null,
        postalCode: null
      })
    }
  }

  onChangePostalCode = async (option, e) => {
    if (e.action === "select-option") {
      this.setState({ postalCode: option.value })
    } else if (e.action === "clear") {
      this.setState({ postalCode: null })
    }
  }

  save = async (e) => {

    let url = `${window.APP_API}/api/v1/account/new`
    if (this.state.uuid !== undefined) {
      url = `${window.APP_API}/api/v1/account/${this.state.uuid}/update`
    }

    const params = {
      custCode: this.state.custCode,
      accCode: this.state.accCode,
      accName: this.state.accName,
      address: this.state.address,
      provinceCode: this.state.provinceCode,
      custName: this.state.custName,
      custAddress: this.state.custAddress,
      cityCode: this.state.cityCode,
      districtCode: this.state.districtCode,
      subDistrictCode: this.state.subDistrictCode,
      postalCode: this.state.postalCode,
      npwp: this.state.npwp,
      npwpName: this.state.npwpName,
      billingPeriod: this.state.billingPeriod,
      billingContact: this.state.billingContact,
      phone: this.state.phone,
      email: this.state.email,
      active: this.state.active
    }

    await axios.post(url,
      params
    )
      .then(async (res) => {

        await NotificationManager.success(res.data.message);

        if (this.state.uuid === undefined) {
          setTimeout(() => {
            window.location.replace(`/master/account/${res.data.uuid}/view`);
          }, 3000)
        }

      }).catch(async (err) => {
        await NotificationManager.error(`Failed  ${err.response.data.message}`);
      })

  }

  async componentDidMount() {
    this.context.handleSetPageSidebarMinified(true);
    this.context.handleSetPageFooter(false);

    await axios.post(`${window.APP_API}/api/v1/ui/faccount`)
      .then(async (res) => {
        this.setState({
          provinces: res.data.provinces,
          customers: res.data.customers,
          couriers: res.data.couriers,
        })
      }).catch(err => {

      })

    if (this.props.params.uuid) {
      this.setState({ uuid: this.props.params.uuid })

      await axios.post(`${window.APP_API}/api/v1/account/${this.props.params.uuid}/view`)
        .then(async (res) => {

          await this.setState({
            custCode: res.data.custCode,
            accCode: res.data.accCode,
            accName: res.data.accName,
            address: res.data.address,
            provinceCode: res.data.provinceCode,
            custName: res.data.custName,
            custAddress: res.data.custAddress,
            cityCode: res.data.cityCode,
            districtCode: res.data.districtCode,
            subDistrictCode: res.data.subDistrictCode,
            postalCode: res.data.postalCode,
            npwp: res.data.npwp,
            npwpName: res.data.npwpName,
            billingPeriod: res.data.billingPeriod,
            billingContact: res.data.billingContact,
            phone: res.data.phone,
            email: res.data.email,
            accountCouriers: res.data.couriers ?? [],
            active: res.data.active
          })
          this.onChangeProvince({ value: res.data.provinceCode, label: res.data.provinceName }, { action: "select-option" })
          this.onChangeCity({ value: res.data.cityCode, label: res.data.cityName }, { action: "select-option" })
          this.onChangeDistrict({ value: res.data.districtCode, label: res.data.districtName }, { action: "select-option" })

        }).catch(err => {

        })

    }
  }

  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item"><Link to="/master/account"> <span className="fa fa-angle-double-left"></span> Account List</Link></li>
          <li className="breadcrumb-item"><Link to="/master/customer">Customer List</Link></li>
          <li className="breadcrumb-item"><Link to="/master/courier">Courier List</Link></li>
        </ol>
        <h1 className="page-header">{!this.state.uuid ? "New" : "Update"} Account <small>...</small></h1>
        <Panel>
          <PanelHeader className="bg-orange-transparent-9 text-right p-4 p-r-0">
            <div className="col-sm-12 p-r-0">
              <button className="btn btn-sm btn-primary f-s-11 text-center" onClick={this.save}><i className="fa fa-save"></i> Save</button>
              <button className="btn btn-sm btn-grey f-s-11 text-red text-center ml-3" onClick={() => { }}><i className="fa fa-minus-circle"></i> Cancel</button>
            </div>
          </PanelHeader>
          <PanelBody>
            <div className="row" >
              <div className='col-sm-6'>
                <div className="form-group row m-b-3">
                  <label className="col-sm-3 col-form-label">Customer</label>
                  <div className="col-sm-8">
                    <Select name="custCode" placeholder={"Customer"}
                      value={this.state.customers.filter(({ value }) => { return value === this.state.custCode; })}
                      options={this.state.customers}
                      onChange={this.onChangeReactSelect}
                      isClearable={!this.state.uuid} isSearchable={true}
                      isDisabled={this.state.uuid} />
                  </div>
                </div>
                <div className="form-group row m-b-1">
                  <label className="col-sm-3 col-form-label">Code</label>
                  <div className="col-sm-8">
                    <input type="text" name="accCode" value={this.state.accCode ?? ""} onChange={this._handleOnChangeInputText} className="form-control form-control-sm" disabled={this.state.uuid} />
                  </div>
                </div>
                <div className="form-group row m-b-1">
                  <label className="col-sm-3 col-form-label">Name</label>
                  <div className="col-sm-8">
                    <input type="text" name="accName" value={this.state.accName ?? ""} onChange={this._handleOnChangeInputText} className="form-control form-control-sm" />
                  </div>
                </div>
                <div className="form-group row m-b-10">
                  <label className="col-sm-3 col-form-label">Active</label>
                  <div className="col-sm-8">
                    <Switch id="active" onChange={this._handleOnChangeSwitch} checked={this.state.active ?? false} />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <Tabs>
                <TabList>
                  <Tab><i className="fa fa-address-card"></i> Pickup Address</Tab>
                  <Tab><i className="fa fa-cog"></i> General</Tab>
                  <Tab><i className="fa fa-money-bill-alt"></i> Services</Tab>
                  <Tab><span className="fa fa-link"></span> Courier Setup</Tab>
                </TabList> 

                <TabPanel>
                  <div className="row">
                    <div className='col-sm-6 bg-grey-transparent-1 pt-2'>
                      <div className="form-group row mb-1">
                        <div className="col-sm-12">
                          <textarea name="address"
                            onChange={this._handleOnChangeInputText} value={this.state.address ?? ""}
                            className='form-control form-control-sm' placeholder="Address" ></textarea>
                        </div>
                      </div>
                      <div className="form-group row mb-1">
                        <div className="col-sm-6">
                          <Select name="province" placeholder={"Province"}
                            value={this.state.provinces.find(({ value }) => { return value === this.state.provinceCode }) ?? ""}
                            options={this.state.provinces}
                            onChange={this.onChangeProvince}
                            isClearable={true} isSearchable={true} />
                        </div>
                        <div className="col-sm-6">
                          <Select name="city" placeholder={"City"}
                            value={this.state.cities.find(({ value }) => { return value === this.state.cityCode }) ?? ""}
                            options={this.state.cities}
                            onChange={this.onChangeCity}
                            isClearable={true} isSearchable={true} />
                        </div>
                      </div>
                      <div className="form-group row mb-1">
                        <div className="col-sm-6">
                          <Select name="district" placeholder={"District"}
                            value={this.state.districts.find(({ value }) => { return value === this.state.districtCode; }) ?? ""}
                            options={this.state.districts}
                            onChange={this.onChangeDistrict}

                            isClearable={true} isSearchable={true} />
                        </div>
                        <div className="col-sm-6">
                          <Select name="subDistrict" placeholder={"Sub District"}
                            value={this.state.subdistricts.find(({ value }) => { return value === this.state.subDistrictCode }) ?? ""}
                            options={this.state.subdistricts} onChange={this.onChangeSubDistrict}
                            isClearable={true} isSearchable={true} />
                        </div>
                      </div>
                      <div className="form-group row mb-1">
                        <div className="col-sm-6">
                          <Select name="postalCode" placeholder={"Postal Code"}
                            value={this.state.postalcodes.find(({ value }) => { return value === this.state.postalCode }) ?? ""}
                            options={this.state.postalcodes} onChange={this.onChangePostalCode}
                            isClearable={true} isSearchable={true} />
                        </div>
                        <div className="col-sm-6">
                          <input type="text" value={this.state.originHub} onChange={() => { }} className="form-control form-control-md bg-grey-transparent-1" readOnly={true} disabled />
                        </div>
                      </div>
                      <div className="form-group row mb-1">
                        <div className="col-sm-6">
                          <input type="text" name="phone" value={this.state.phone ?? ""}
                            onChange={this._handleOnChangeInputText}
                            className="form-control form-control-sm" placeholder="Phone" />
                        </div>
                        <div className="col-sm-6">
                          <input type="text" name="email" value={this.state.email ?? ""}
                            onChange={this._handleOnChangeInputText}
                            className="form-control form-control-sm" placeholder="Email" />
                        </div>
                      </div>
                    </div>

                  </div>
                </TabPanel>
                <TabPanel>
                  General
                </TabPanel>
                <TabPanel>
                  <div className="row">
                    <div className='col-sm-6'>

                      <div className="form-group row m-b-3">
                        <label className="col-sm-3 col-form-label">Tax Number</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control form-control-sm" name="npwp" value={this.state.npwp ?? ""} onChange={this._handleOnChangeInputText}></input>
                        </div>
                      </div>
                      <div className="form-group row m-b-3">
                        <label className="col-sm-3 col-form-label">Tax Name</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control form-control-sm" name="npwpName" value={this.state.npwpName} onChange={this._handleOnChangeInputText}></input>
                        </div>
                      </div>
                      <div className="form-group row m-b-3">
                        <label className="col-sm-3 col-form-label">Billing Period</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control form-control-sm" name="billingPeriod" value={this.state.billingPeriod} onChange={this._handleOnChangeInputText}></input>
                        </div>
                      </div>
                      <div className="form-group row m-b-3">
                        <label className="col-sm-3 col-form-label">Billing PiC</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control form-control-sm" name="billingContact" value={this.state.billingContact} onChange={this._handleOnChangeInputText}></input>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="table-responsive table-striped bg-white table-hover">
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th>No</th>
                              <th>Courier</th>
                              <th>Last Update</th>
                              <th>&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(this.state.accountCouriers ?? []).map((val, key) => {
                              return (
                                <tr key={val.id}>
                                  <td>{(key + 1)} </td>
                                  <td>
                                    <div className="form-inline">
                                      <button className="btn btn-sm" key={val.uuid} onClick={() => {
                                        const { id, attributes, courier_code } = val
                                        this._handleOnChangeEditCourierSetup({ id, attributes, courier_code })
                                      }}><i className="fas fa-edit fa-fw" ></i></button>
                                      <select name="selectedCourierSetup" className="form-control form-control-sm" value={val.courier_code} defaultValue={val.courier_code}
                                        onChange={this._onChangeSelectTableCourierSetup(val.id)}
                                        disabled={val.id !== 0}  >
                                        <option value="">-</option>
                                        {this.state.couriers.map((courier, key) => {
                                          return (<option key={courier.value} value={courier.value}>{courier.label}</option>)
                                        })}
                                      </select>
                                    </div>
                                  </td>
                                  <td>{val.updated_at ? moment(val.updated_at).format("YYYY-MM-DD HH:mm") : "-"}</td>
                                  <td><button className="btn btn-xs btn-danger m-0" onClick={this.deleteCourierSetup(val)}><i className="fa fa-sm fa-trash"></i></button></td>
                                </tr>
                              )
                            })}
                          </tbody>
                          <tbody>
                            <tr>
                              <td colSpan={4}><button className="btn btn-sm btn-primary mt-2" onClick={this.addCourierSetupRow}>Add</button></td>
                              <td>&nbsp;</td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colSpan={4}>Selected: {this.state.selectedIdCourierSetup} / {this.state.selectedCodeCourierSetup}</td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                    <div className="col-sm-6">

                      <div className="row">
                        <div className="col-sm-12">
                          <strong>{this.state.selectedCodeCourierSetup}</strong>
                          <JSONInput
                            id='selectedAttributesCourierSetup'
                            name="selectedAttributesCourierSetup"
                            placeholder={this.state.selectedAttributesCourierSetup}
                            value={this.state.selectedAttributesCourierSetup}
                            onChange={this.onChangeJsonFormat}
                            colors={{
                              background: "rgba(182,194,201,.1)",
                              default: "black",
                              keys: "red"
                            }}
                            theme={"light_mitsuketa_tribute"}
                            locale={locale}
                            onKeyPressUpdate={true}
                            waitAfterKeyPress={1300}
                          />

                          <button className="btn btn-sm btn-primary mt-2" onClick={this.saveCourierSetup}> <i className="fa fa-save"></i> Update Setup</button>
                          <button className="btn btn-sm btn-grey f-s-11 text-red text-center mt-2 ml-3" onClick={async () => {
                            const couriers = this.state.accountCouriers.filter((item) => { return item.id !== 0 })

                            this.setState({
                              selectedCodeCourierSetup: "",
                              selectedIdCourierSetup: 0,
                              selectedAttributesCourierSetup: {},
                              accountCouriers: couriers
                            })
                          }}><i className="fa fa-minus-circle"></i> Reset Setup</button>
                        </div>
                      </div>

                    </div>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </PanelBody>
        </Panel>
        <NotificationContainer />
      </div>
    )
  }
}

export default withParams(Account);
