import React from "react";
import { Link, useParams } from 'react-router-dom';
import Switch from "react-switch";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import axios from 'axios'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Panel, PanelBody } from '../../components/panel/panel.jsx';
import { PageSettings } from '../../config/page-settings.js';
import Select from 'react-select';

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class Customer extends React.Component {

  static contextType = PageSettings;

  state = {
    provinces: [],
    cities: [],
    districts: [],
    subdistricts: [],
    postalcodes: [],
    provinceCode: null,
    provinceName: null,
    active: true,
  }

  _handleOnChangeInputText = async (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  _handleOnChangeSwitch = async (c, e, id) => {
    this.setState({ [id]: c })
  }

  onChangeProvince = async (option, e) => {
    if (e.action === "select-option") {
      this.setState({ provinceCode: option.value, provinceName: option.label })
      await axios.post(`${window.APP_API}/api/v1/ui/city`, {
        prov_code: option.value
      }).then(res => {
        const cities = res.data;
        this.setState({ cities: cities })
      }).catch(err => {

      })
    } else if (e.action === "clear") {
      this.setState({
        provinceCode: null,
        provinceName: null,
        cityCode: null,
        cityName: null,
        districtCode: null,
        districtName: null,
        subDistrictCode: null,
        subDistrictName: null,
        postalCode: null,
        cities: [],
        districts: [],
        subDistricts: [],
        postalCodes: [],
      })
    }
  }

  onChangeCity = async (option, e) => {
    if (e.action === "select-option") {
      this.setState({ cityCode: option.value, cityName: option.label })
      await axios.post(`${window.APP_API}/api/v1/ui/district`, {
        city_code: option.value
      }).then(res => {
        const districts = res.data;
        this.setState({ districts: districts })
      }).catch(err => {

      })
    } else if (e.action === "clear") {
      this.setState({
        cityCode: null,
        cityName: null,
        districtCode: null,
        districtName: null,
        subDistrictCode: null,
        subDistrictName: null,
        postalCode: null,
        districts: [],
        subDistricts: [],
        postalCodes: [],
      })
    }
  }

  onChangeDistrict = async (option, e) => {
    if (e.action === "select-option") {
      this.setState({ districtCode: option.value, districtName: option.label })

      await axios.post(`${window.APP_API}/api/v1/ui/subdistrict`, {
        district_code: option.value
      }).then(res => {
        const subdistricts = res.data;
        this.setState({ subdistricts: subdistricts })
      }).catch(err => {
      })

      await axios.post(`${window.APP_API}/api/v1/ui/postalcode`, {
        district_code: option.value
      }).then(async (res) => {
        const postalcodes = res.data;
        this.setState({ postalcodes: postalcodes })
      }).catch(err => {
      })

    } else if (e.action === "clear") {
      this.setState({
        cityCode: null,
        cityName: null,
        districtCode: null,
        districtName: null,
        subDistrictCode: null,
        subDistrictName: null,
        postalCode: null,
        districts: [],
        subDistricts: [],
        postalCodes: [],
      })
    }
  }

  onChangeSubDistrict = async (option, e) => {
    if (e.action === "select-option") {
      this.setState({ subDistrictCode: option.value, subDistrictName: option.label })
    } else if (e.action === "clear") {
      this.setState({
        subDistrictCode: null, 
        subDistrictName: null,
        postalCode: null
      })
    }
  }

  onChangePostalCode = async (option, e) => {
    if (e.action === "select-option") {
      this.setState({ postalCode: option.value })
    } else if (e.action === "clear") {
      this.setState({ postalCode: null })
    }
  }


  save = async (e) => {

    let url = `${window.APP_API}/api/v1/customer/new`
    if (this.state.uuid !== undefined) {
      url = `${window.APP_API}/api/v1/customer/${this.state.uuid}/update`
    }

    const data = {
      custCode: this.state.custCode,
      custName: this.state.custName,
      custAddress: this.state.custAddress,
      provinceCode: this.state.provinceCode,
      provinceName: this.state.provinceName,
      cityCode: this.state.cityCode,
      cityName: this.state.cityName,
      districtCode: this.state.districtCode,
      districtName: this.districtName,
      subDistrictCode: this.state.subDistrictCode,
      billingContact: this.state.billingContact,
      billingPeriod: this.state.billingPeriod,
      taxName: this.state.taxName,
      taxNum: this.state.taxNum,
      active: this.state.active,
      email: this.state.email,
      phone: this.state.phone,
    }

    await axios.post(url,
      data
    )
      .then(async(res) => {

        await NotificationManager.success(res.data.message);

        if (this.state.uuid === undefined) {
          setTimeout(() => {
            window.location.replace(`/master/customer/${res.data.uuid}/view`);
          }, 3000)

        }

      }).catch( async (err) => {
        await NotificationManager.error(`Failed  ${err.response.data.message}`);
      })

  }

  async componentDidMount() {
    this.context.handleSetPageSidebarMinified(true);
    this.context.handleSetPageFooter(false);

    await axios.post(`${window.APP_API}/api/v1/ui/fcustomer`)
      .then(async (res) => {
        this.setState({
          provinces: res.data.provinces
        })
      }).catch(err => {

      })


    if (this.props.params.uuid) {
      this.setState({ uuid: this.props.params.uuid })

      await axios.get(`${window.APP_API}/api/v1/customer/view/${this.props.params.uuid}`)
        .then(async (res) => {
          await this.setState({
            custCode: res.data.custCode,
            custName: res.data.custName,
            custAddress: res.data.custAddress,
            provinceCode: res.data.provinceCode,
            cityCode: res.data.cityCode,
            districtCode: res.data.districtCode,
            subDistrictCode: res.data.subDistrictCode,
            postalCode: res.data.postalCode,
            taxNum: res.data.taxNum,
            taxName: res.data.taxName,
            billingPeriod: res.data.billingPeriod,
            billingContact: res.data.billingContact,
            phone: res.data.phone,
            email: res.data.email,
            active: res.data.active
          })
          this.onChangeProvince({ value: res.data.provinceCode, label: res.data.provinceName }, { action: "select-option" })
          this.onChangeCity({ value: res.data.cityCode, label: res.data.cityName }, { action: "select-option" })
          this.onChangeDistrict({ value: res.data.districtCode, label: res.data.districtName }, { action: "select-option" })
        }).catch(err => {

        })
    }
  }

  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item"><Link to="/master/customer"> <span className="fa fa-angle-double-left"></span> Customer List</Link></li>
        </ol>
        <h1 className="page-header">{!this.state.uuid ? "New" : "Update"} Customer <small>Maintenance Data</small></h1>
        <Panel>
          <PanelBody>
            <div className="row" >
              <div className='col-sm-6'>
                <div className="form-group row m-b-1">
                  <label className="col-sm-3 col-form-label">Code</label>
                  <div className="col-sm-8">
                    <input type="text" name="custCode" value={this.state.custCode} onChange={this._handleOnChangeInputText} className="form-control form-control-sm" disabled={this.state.uuid} />
                  </div>
                </div>
                <div className="form-group row m-b-1">
                  <label className="col-sm-3 col-form-label">Name</label>
                  <div className="col-sm-8">
                    <input type="text" name="custName" value={this.state.custName} onChange={this._handleOnChangeInputText} className="form-control form-control-sm" />
                  </div>
                </div>
                <div className="form-group row m-b-10">
                  <label className="col-sm-3 col-form-label">Active</label>
                  <div className="col-sm-8">
                    <Switch id="active" onChange={this._handleOnChangeSwitch} checked={this.state.active ?? false} className="react-switch" />
                  </div>
                </div>
                <div className="form-group row m-b-1">
                  <label className="col-sm-3 col-form-label">&nbsp;</label>
                  <div className="col-sm-8">
                    <button type="button" className="btn btn-sm btn-primary" onClick={this.save}><span className="fa fa-sm fa-save"></span> Save</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <Tabs>
                <TabList>
                  <Tab>General</Tab> 
                  <Tab>Billing</Tab>
                </TabList>
                <TabPanel>
                  <div className="row">
                    <div className='col-sm-6 bg-grey-transparent-1 pt-2'>
                      <div className="form-group row mb-1">
                        <div className="col-sm-12">
                          <textarea name="custAddress"
                            onChange={this._handleOnChangeInputText} value={this.state.custAddress}
                            className='form-control form-control-sm' placeholder="Address" ></textarea>
                        </div>
                      </div>
                      <div className="form-group row mb-1">
                        <div className="col-sm-6">
                          <Select name="province" placeholder={"Province"}
                            value={this.state.provinces.find(({ value }) => { return value === this.state.provinceCode })}
                            options={this.state.provinces}
                            onChange={this.onChangeProvince}
                            isClearable={true} isSearchable={true} />
                        </div>
                        <div className="col-sm-6">
                          <Select name="city" placeholder={"City"}
                            value={this.state.cities.find(({ value }) => { return value === this.state.cityCode })}
                            options={this.state.cities}
                            onChange={this.onChangeCity}
                            isClearable={true} isSearchable={true} />
                        </div>
                      </div>
                      <div className="form-group row mb-1">
                        <div className="col-sm-6">
                          <Select name="consigneeDistrict" placeholder={"District"}
                            value={this.state.districts.find(({ value }) => { return value === this.state.districtCode; })}
                            options={this.state.districts}
                            onChange={this.onChangeDistrict}

                            isClearable={true} isSearchable={true} />
                        </div>
                        <div className="col-sm-6">
                          <Select name="consigneeSubDistrict" placeholder={"Sub District"}
                            value={this.state.subdistricts.find(({ value }) => { return value === this.state.subDistrictCode })}
                            options={this.state.subdistricts} onChange={this.onChangeSubDistrict}
                            isClearable={true} isSearchable={true} />
                        </div>
                      </div>
                      <div className="form-group row mb-1">
                        <div className="col-sm-6">
                          <Select name="consigneePostalCode" placeholder={"Postal Code"}
                            value={this.state.postalcodes.find(({ value }) => { return value === this.state.postalCode })}
                            options={this.state.postalcodes} onChange={this.onChangePostalCode}
                            isClearable={true} isSearchable={true} />
                        </div>
                        <div className="col-sm-6">
                          <input type="text" value={this.state.originHub} className="form-control form-control-md bg-grey-transparent-1" readOnly disabled />
                        </div>
                      </div>
                      <div className="form-group row mb-1">
                        <div className="col-sm-6">
                          <input type="text" name="phone" value={this.state.phone}
                            onChange={this._handleOnChangeInputText}
                            className="form-control form-control-sm" placeholder="Phone" />
                        </div>
                        <div className="col-sm-6">
                          <input type="text" name="email" value={this.state.email}
                            onChange={this._handleOnChangeInputText}
                            className="form-control form-control-sm" placeholder="Email" />
                        </div>
                      </div>
                    </div>

                  </div>
                </TabPanel> 
                <TabPanel>
                  <div className="row">
                    <div className='col-sm-6'>

                      <div className="form-group row m-b-3">
                        <label className="col-sm-3 col-form-label">NPWP NO.</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control form-control-sm" name="taxNum" value={this.state.taxNum ?? ""} onChange={this._handleOnChangeInputText}></input>
                        </div>
                      </div>
                      <div className="form-group row m-b-3">
                        <label className="col-sm-3 col-form-label">NPWP NAME</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control form-control-sm" name="taxName" value={this.state.taxName} onChange={this._handleOnChangeInputText}></input>
                        </div>
                      </div>
                      <div className="form-group row m-b-3">
                        <label className="col-sm-3 col-form-label">Billing Period</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control form-control-sm" name="billingPeriod" value={this.state.billingPeriod} onChange={this._handleOnChangeInputText}></input>
                        </div>
                      </div>
                      <div className="form-group row m-b-3">
                        <label className="col-sm-3 col-form-label">Billing PiC</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control form-control-sm" name="billingContact" value={this.state.billingContact} onChange={this._handleOnChangeInputText}></input>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>

              </Tabs>
            </div>
          </PanelBody>
        </Panel>
        <NotificationContainer />
      </div>
    )
  }
}

export default withParams(Customer);
