import React from "react";
import { Link, useParams } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Switch from "react-switch";
import axios from 'axios'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Panel, PanelBody,PanelHeader } from '../../components/panel/panel.jsx';
import { PageSettings } from '../../config/page-settings.js'; 
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/id';
import { isEmpty } from "../../helper/helper.js" 

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class Courier extends React.Component { 
  static contextType = PageSettings;

  state = { 
    active: true,
    jsonIsValid: true,
    endpoint: {}
  }

  _handleOnChangeInputText = async (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  _handleOnChangeSwitch = async (c, e, id) => {
    this.setState({ [id]: c })
  }

  onChangeJsonFormat = async (e) => {
    this.setState({
      jsonIsValid: (e.jsObject === undefined) ? false : true,
      endpoint: e.jsObject ?? {}
    }) 
  }

  save = async (e) => {

    if (!this.state.jsonIsValid) {
      await NotificationManager.warning(`JSON Params is not valid !!`);
      return;
    }
 
    let url = `${window.APP_API}/api/v1/courier/new`
    if (this.state.uuid !== undefined) {
      url = `${window.APP_API}/api/v1/courier/${this.state.uuid}/update`
    }

    const params = {
      courierCode:this.state.courierCode,
      courierName:this.state.courierName,
      endpoint: this.state.endpoint ?? {} ,
      active: this.state.active
    }

    await axios.post(url,
     params
    )
      .then(async (res) => {

        await NotificationManager.success(res.data.message);

        if (this.state.uuid === undefined) {
          setTimeout(() => {
            window.location.replace(`/master/courier/${res.data.uuid}/view`);
          }, 3000) 
        }

      }).catch(async (err) => { 
        await NotificationManager.error(`Failed  ${err.response.data.message}`);
      }) 
  }

  async componentDidMount() {
    if ( !isEmpty(this.props.params.uuid)) {
      this.setState({ uuid: this.props.params.uuid })

      await axios.post(`${window.APP_API}/api/v1/courier/${this.props.params.uuid}/view`)
        .then(async (res) => {
          this.setState( {
            courierCode: res.data.courierCode,
            courierName: res.data.courierName,
            endpoint: res.data.endpoint,
            api_retry: res.data.api_retry,
            api_max_retry: res.data.api_max_retry,
            updated_by: res.data.updated_by,
            updated_at: res.data.updated_at,
            active: res.data.active
          })
        }).catch(err => {

				})
      this.context.handleSetPageSidebarMinified(true);
      this.context.handleSetPageFooter(false);
    }
  }

  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item"><Link to="/master/courier"> <span className="fa fa-angle-double-left"></span> Courier List</Link></li>
        </ol>
        <h1 className="page-header">{!this.state.uuid ? "New" : "Update"} Courier<small>...</small></h1>
        <Panel>
        <PanelHeader className="bg-orange-transparent-9 text-right p-4 p-r-0">
            <div className="col-sm-12 p-r-0">
              <button className="btn btn-sm btn-primary f-s-11 text-center" onClick={this.save}><i className="fa fa-save"></i> Save</button>
              <button className="btn btn-sm btn-grey f-s-11 text-red text-center ml-3" onClick={() => { }}><i className="fa fa-minus-circle"></i> Cancel</button>
            </div>
          </PanelHeader>
          <PanelBody>
            <div className="row" >
              <div className='col-sm-6'>
                <div className="form-group row m-b-1">
                  <label className="col-sm-3 col-form-label">Code</label>
                  <div className="col-sm-8">
                    <input type="text" name="courierCode" value={this.state.courierCode ?? ""} onChange={this._handleOnChangeInputText} className="form-control form-control-sm" disabled={this.state.uuid} />
                  </div>
                </div>
                <div className="form-group row m-b-1">
                  <label className="col-sm-3 col-form-label">Name</label>
                  <div className="col-sm-8">
                    <input type="text" name="courierName" value={this.state.courierName ?? ""} onChange={this._handleOnChangeInputText} className="form-control form-control-sm" />
                  </div>
                </div>
                <div className="form-group row m-b-10">
                  <label className="col-sm-3 col-form-label">Active</label>
                  <div className="col-sm-8">
                    <Switch id="active" onChange={this._handleOnChangeSwitch} checked={this.state.active ?? false} />
                  </div>
                </div> 
              </div> 
            </div>
            <div className="row">
              <Tabs>
                <TabList>
                  <Tab>General</Tab>
                  <Tab>End Point</Tab>
                </TabList>
                <TabPanel>
                  General
                </TabPanel>
                <TabPanel>
                  <div className="row">
                    <div className="col-sm-12"> 
                    <JSONInput
                            id='selectedAttributesCourierSetup'
                            name="selectedAttributesCourierSetup"
                            placeholder={this.state.endpoint ?? {}}
                            value={this.state.endpoint ?? {}}
                            onChange={this.onChangeJsonFormat}
                            colors={{
                              background: "rgba(182,194,201,.1)",
                              default: "black",
                              keys: "red"
                            }}
                            theme={"light_mitsuketa_tribute"}
                            locale={locale}
                            onKeyPressUpdate={true}
                            waitAfterKeyPress={1300}
                            width={1000}
                          /> 
                    </div>
                    </div>
                </TabPanel>
                </Tabs>
            </div>
          </PanelBody>
        </Panel>
        <NotificationContainer />
      </div>
    )
  }
}

export default withParams(Courier);
