import React from 'react';
import axios from 'axios'
import { Link, useParams } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { PageSettings } from '../../config/page-settings.js';
import moment from "moment"
import Select from 'react-select';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { isEmpty } from "../../helper/helper.js"

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class RunSheet extends React.Component {

  static contextType = PageSettings;

  state = {
    uuid: null,
    currentPage: 1,
    totalRows: 0,
    drivers: [],
    runsheetDetails: [],
    manifestDate: moment().format("YYYY-MM-DD")
  }

  _handleOnChangeInputText = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  _handleKeyDown = async (e) => {
    if (e.target.name === "packageNum" && e.keyCode === 13) { // if enter or tab
      await this.addPackage();
      this.setState({ packageNum: "" })

    }
    if (e.target.name === "manifestNum" && e.keyCode === 13) {
      alert(`Enter ${e.target.name}`)
    }
  }

  onChangeDriver = (option, e) => {
    if (e.action === "select-option") {
      const driver = this.state.drivers.find(val => val.value === option.value);
      if (driver !== undefined) {
        this.setState({ driverPhone: driver.driver_phone })
      }

      this.setState({ driverCode: option.value, driverName: option.label })
    } else if (e.action === "clear") {
      this.setState({ driverCode: "", driverName: "", driverPhone: "" })
    }
  }

  addPackage = async (e) => {
    console.log()
    if (isEmpty(this.state.packageNum)) {
      await NotificationManager.warning("Please input Package Num");
      return;
    }

    const params = {
      runsheetNum: this.state.runsheetNum,
      shipmentNum: this.state.packageNum
    }


    await axios.post(`${window.APP_API}/api/v1/runsheet/add-detail`,
      params)
      .then(async (res) => {

        this.setState(prevState => ({
          runsheetDetails: [res.data, ...prevState.runsheetDetails],
        }))

      }).catch(async (err) => {
        await NotificationManager.error(err.response.data.message);
      })
  }


  save = async (e) => {

    let url = {}
    if (isEmpty(this.state.uuid)) {
      console.log(`isEmpty: ${this.state.uuid}`)
      url = `${window.APP_API}/api/v1/runsheet/create`
    } else {
      url = `${window.APP_API}/api/v1/runsheet/${this.state.uuid}/update`
    }
    const params = {
      runsheetDate: this.state.runsheetDate,
      driverName: this.state.driverName,
      driverCode: this.state.driverCode,
      driverPhone: this.state.driverPhone,
      remarks: this.state.remarks
    }

    await axios.post(url,
      params)
      .then(async (res) => {

        await NotificationManager.success(res.data.message);
        if (isEmpty(this.props.params.uuid)) {
          setTimeout(() => {
            window.location.replace(`/runsheet/${res.data.uuid}/view`);
          }, 2000)
        }

      }).catch(err => {
      })
  }

  deleteLine = val => async () => {
    await axios.post(`${window.APP_API}/api/v1/runsheet/${val}/deleteline`,
      { id: val }
    ).then(async (res) => {
      await NotificationManager.success(res.data.message);

      const rDetails = this.state.runsheetDetails;
      const idx = rDetails.map(e => e.id).indexOf(val.id);
      rDetails.splice(idx, 1);
      this.setState({
        runsheetDetails: rDetails,
      });

    }).catch(async (err) => {
      await NotificationManager.error(`Failed  ${err.response.data.message}`);
    })
  }

  confirm = async () => {
    await axios.post(`${window.APP_API}/api/v1/runsheet/${this.state.uuid}/confirm`,
    ).then(async (res) => {
      await NotificationManager.success(res.data.message);

      this.setState({
        runsheetStatus: res.data.status
      })

      // const rDetails = this.state.runsheetDetails;
      // const idx = rDetails.map(e => e.id).indexOf(val.id);
      // rDetails.splice(idx, 1);
      // this.setState({
      //   runsheetDetails: rDetails,
      // });

    }).catch(async (err) => {
      await NotificationManager.error(`Failed  ${err.response.data.message}`);
    })
  }

  print = async (e) => {
    await NotificationManager.success(`Print Runsheet`);
    window.open(`${window.APP_API}/api/v1/runsheet/${this.state.uuid}/print`, "_blank", "noreferrer");
  }

  async componentDidMount() {
    this.context.handleSetPageSidebarMinified(true);
    this.context.handleSetPageFooter(false);

    this.setState({
      rowsPerPage: this.context.defaultRowsPerPage
    })

    await axios.post(`${window.APP_API}/api/v1/ui/frunsheet`)
      .then(async (res) => {
        this.setState({
          runsheetDate: res.data.runsheetDate,
          drivers: res.data.drivers ?? [],
        })
      }).catch(err => {

      })

    if (!isEmpty(this.props.params.uuid)) {
      this.setState({ uuid: this.props.params.uuid })

      await axios.post(`${window.APP_API}/api/v1/runsheet/${this.props.params.uuid}/view`)
        .then(async (res) => {
          const details = [];
          (res.data.runsheet_details ?? []).forEach(item => {
            details.push({
              id: item.id,
              shipment_num: item.shipment_num,
              consignee_name: item.shipment.consignee_name,
              consignee_district_name: item.shipment.consignee_district_name,
              consignee_subdistrict_name: item.shipment.consignee_subdistrict_name,
              consignee_postalcode: item.shipment.consignee_postalcode,
              service_type: item.shipment.service_type,
            })
          })

          this.setState({
            runsheetStatus: res.data.runsheet_status,
            runsheetNum: res.data.runsheet_num,
            runsheetDate: res.data.runsheet_date,
            driverCode: res.data.runner_code,
            driverName: res.data.runner_name,
            driverPhone: res.data.runner_phone,
            remarks: res.data.remarks,
            runsheetDetails: details,
          })
        }).catch(async (err) => {

        })
    }

  }

  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item"><Link to="/runsheet"> <span className="fa fa-angle-double-left"></span> Run Sheet List</Link></li>
        </ol>
        <h1 className="page-header">{!this.state.uuid ? "New" : "Update"} Run Sheet <small>Maintenance Data {this.state.runsheetStatus} </small></h1>
        <Panel>
          <PanelHeader className="bg-orange-transparent-9 text-right p-4 p-r-0">
            <div className="col-sm-12 p-r-0">
              {this.state.runsheetStatus !== "CONFIRM" ? 
              <div className="btn-group btn-group-sm p-r-0">
                <button className="btn btn-sm btn-primary f-s-11 text-center" onClick={this.save} disabled={(parseInt(this.state.shipmentStatus) > 100)}><i className="fa fa-save"></i> {!this.state.uuid ? "Create" : "Update"}</button>
                {1 !== 3 ? (<UncontrolledButtonDropdown>
                  <DropdownToggle className={"btn btn-sm"} color="primary" caret></DropdownToggle>
                  <DropdownMenu>
                    {this.runsheetStatus !== "CONFIRM" ? <DropdownItem className="dropdown-item f-s-11" onClick={this.confirm} disabled={true}><i className="fa fa-thumbs-up"></i> Confirm</DropdownItem> : null} 
                  </DropdownMenu>
                </UncontrolledButtonDropdown>) : null}
              </div>: null }
              {this.state.runsheetStatus === "CONFIRM" ? <button className="btn btn-sm btn-green f-s-11 text-center ml-3" onClick={this.print}><i className="fa fa-print"></i> Print</button> : null}
              <button className="btn btn-sm btn-grey f-s-11 text-center ml-3" onClick={() => { }}><i className="fa fa-minus-circle"></i> Cancel</button>

            </div>
          </PanelHeader>
          <PanelBody>
            <div className='row'>

              <div className='col-sm-8'>
                <div className="form-group row m-b-0">
                  <label className="col-sm-3 col-form-label">Run Sheet Num</label>
                  <div className='col-sm-8'>
                    <div className="form-inline">
                      <div className="form-group m-r-10">
                        <input type="text" className="form-control form-control-sm" id="exampleInputEmail2" value={this.state.runsheetNum ?? "AUTO GENERATE"} disabled={true} />
                      </div>
                      <div className="form-group m-r-10">
                        <label className="form-check-label" for="runsheetDate">Date</label>
                        <input type="date" className="form-control form-control-sm ml-2" id="runsheetDate" value={this.state.runsheetDate} onChange={this._handleOnChangeInputText} />
                      </div>
                      <div className="form-group m-r-10">
                        <label className="form-check-label" for="runsheetStatus">Status</label>
                        <input type="text" className="form-control form-control-sm ml-2" id="runsheetStatus" value={this.state.runsheetStatus ?? "NEW"} disabled={true} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group row m-b-3">
                  <label className="col-sm-3 col-form-label">Driver</label>
                  <div className="col-sm-4">
                    <Select name="driverCode" placeholder={"Driver"}
                      value={this.state.drivers.filter(({ value }) => { return value === this.state.driverCode; })}
                      options={this.state.drivers}
                      onChange={this.onChangeDriver}
                      isClearable={true} isSearchable={true} />
                  </div>
                  <div className="col-sm-4">
                    <input type="text" name="driverPhone" value={this.state.driverPhone} onChange={this._handleOnChangeInputText} placeholder="driver Phone" className="form-control form-control mt-1 pt-1" maxLength={15} />
                  </div>
                </div>

                <div className="form-group row m-b-3">
                  <label className="col-sm-3 col-form-label">Remarks</label>
                  <div className="col-sm-8">
                    <textarea name="shipperAddress"
                      onChange={this._handleOnChangeInputText} value={this.state.remarks}
                      className='form-control form-control-sm' placeholder="Remarks / Note"></textarea>
                  </div>
                </div>
              </div>

              {["NEW"].includes(this.state.runsheetStatus) ?
                <div className='col-sm-4'>
                  <div className="row mb-3" >
                    <div className="col-md-7">
                      <label className="form-label">PACKAGE</label>
                      <div className="input-group">
                        <input className="form-control form-control-lg" type="text" name="packageNum" autoFocus onChange={this._handleOnChangeInputText} onKeyDown={this._handleKeyDown} value={this.state.packageNum ?? ""} placeholder="Package" required="" />
                        <div className="input-group-text m-0 p-0">
                          <button className="btn btn-lg m-0 p-0" onClick={this.searchPackage}><span className="fas fa-search text-primary fs-2"></span></button>

                        </div>
                      </div>
                    </div>
                  </div>
                </div> : null
              }

            </div>
          </PanelBody>
        </Panel>
        {1 === 1 ?
          <Panel>
            <PanelBody>
              <div className='row'>
                <div className='col-sm-8'>
                  <div className="table-responsive table-striped bg-white">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>Package</th>
                          <th>Service</th>
                          <th>Consignee</th>
                          <th>District</th>
                          <th>Sub District</th>
                          <th>Postal Code</th>
                          <th><span className="fa fa-cog"></span></th>
                        </tr>

                      </thead>
                      <tbody>
                        {this.state.runsheetDetails.map((val, key) => {
                          return (
                            <tr key={val.id}>
                              <td>{this.state.runsheetDetails.length - (key)}.</td>
                              <td>{val.shipment_num}</td>
                              <td>{val.service_type}</td>
                              <td>{val.consignee_name}</td>
                              <td>{val.consignee_district_name}</td>
                              <td>{val.consignee_subdistrict_name}</td>
                              <td>{val.consignee_postalcode}</td>
                              <td>{["NEW"].includes(this.state.runsheetStatus) ? <button className="btn btn-xs btn-danger m-0" onClick={this.deleteLine(val.id)}><i className="fa fa-sm fa-trash"></i></button>: null}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </PanelBody>
          </Panel> : null}
        <NotificationContainer />
      </div>
    )
  }
}


export default withParams(RunSheet); 