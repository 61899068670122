import React from 'react';
import axios from 'axios'
import { Link, useParams } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { PageSettings } from '../../config/page-settings.js';
import moment from "moment" 
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { isEmpty } from "../../helper/helper.js"
import  "./style.css"

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class RunSheetPOD extends React.Component {

  static contextType = PageSettings;

  state = {
    uuid: null,
    currentPage: 1,
    totalRows: 0,
    drivers: [],
    runsheetNum: "",
    runsheetDetails: [],
    reasonCodes: [],
    relations: [],
    manifestDate: moment().format("YYYY-MM-DD")
  }

  _handleOnChangeInputText = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  _handleKeyDown = async (e) => {
    if (e.target.name === "runsheetNum" && e.keyCode === 13) { // if enter or tab 
      if (isEmpty(this.state.runsheetNum)) {
        await NotificationManager.warning("Please input Runsheet Num");
        return;
      }
      await this.findRunSheet();
    }
  }

  _handleTableOnChange = id => async (e) => {
    console.log(e.target.name)
    const rsd = this.state.runsheetDetails.map((c, i) => {
      let rec = c;
      if (c.rdid === id) {
        rec[e.target.name] = e.target.value;
      }
      return c;
    })

    this.setState({
      runsheetDetails: rsd
    })
  }


  findRunSheet = async (e) => {

    await axios.post(`${window.APP_API}/api/v1/runsheet/find-runsheet`,
      { runsheetNum: this.state.runsheetNum })
      .then(async (res) => {

        this.setState(prevState => ({
          uuid: res.data.uuid,
          runsheetStatus: res.data.runsheetStatus,
          runnerName: res.data.runnerName,
          runnerPhone: res.data.runnerPhone,
          runsheetDetails: res.data.details,
          remarks: res.data.remarks,
        }))

      }).catch(async (err) => {
        await NotificationManager.error(err.response.data.message);
        this.setState({ runsheetNum: "" })
      })
  }

  save = async (e) => {

    let url = `${window.APP_API}/api/v1/runsheet/pods` 
    await axios.post(url,
      {rsd: this.state.runsheetDetails})
      .then(async (res) => {

        await NotificationManager.success(res.data.message); 

      }).catch(err => {
      })
  }

  close = async () => {
    await axios.post(`${window.APP_API}/api/v1/runsheet/${this.state.uuid}/close`,
    ).then(async (res) => {
      await NotificationManager.success(res.data.message);

      this.setState(prevState => ({
        uuid: "",
        runsheetNum:  "",
        runsheetDate: "",
        runsheetStatus: "",
        runnerName: "",
        runnerPhone: "",
        runsheetDetails: [],
        remarks: "",
      }))

    }).catch(async (err) => {
      await NotificationManager.error(`Failed  ${err.response.data.message}`);
    })
  }

  reset = async () => {
    this.setState(prevState => ({
      uuid: "",
      runsheetNum:  "",
      runsheetDate: "",
      runsheetStatus: "",
      runnerName: "",
      runnerPhone: "",
      runsheetDetails: [],
      remarks: "",
    }))
  }

  async componentDidMount() {
    this.context.handleSetPageSidebarMinified(true);
    this.context.handleSetPageFooter(false);

    this.setState({
      rowsPerPage: this.context.defaultRowsPerPage
    })

    await axios.post(`${window.APP_API}/api/v1/ui/frunsheetpod`)
      .then(async (res) => {
        this.setState({
          runsheetDate: res.data.runsheetDate,
          reasonCodes: res.data.reasonCodes ?? [],
          relations: res.data.relations ?? []
        })
      }).catch(err => {

      })

  }

  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item"><Link to="/runsheet"> <span className="fa fa-angle-double-left"></span> Run Sheet List</Link></li>
        </ol>
        <h1 className="page-header">Run Sheet POD <small>Confirm Delivery</small></h1>
        <Panel>
          <PanelHeader className="bg-orange-transparent-9 text-right p-4 p-r-0">
            <div className="col-sm-12 p-r-0">
              {!isEmpty(this.state.uuid) && this.state.runsheetStatus === "CONFIRM" ?
                <div className="btn-group btn-group-sm p-r-0">
                  <button className="btn btn-sm btn-primary f-s-11 text-center" onClick={this.save}><i className="fa fa-save"></i> Save</button>
                  {1 !== 3 ? (<UncontrolledButtonDropdown>
                    <DropdownToggle className={"btn btn-sm"} color="primary" caret></DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem className="dropdown-item f-s-11" onClick={this.close}><i className="fa fa-thumbs-up"></i> Close</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>) : null}
                </div> : null}
              {this.state.runsheetStatus === "CONFIRM" ? <button className="btn btn-sm btn-green f-s-11 text-center ml-3" onClick={this.print}><i className="fa fa-print"></i> Print</button> : null}
              <button className="btn btn-sm btn-grey f-s-11 text-center ml-3" onClick={this.reset}><i className="fas fa-sync"></i> Reset</button>

            </div>
          </PanelHeader>
          <PanelBody>
            <div className='row'> 
              <div className='col-sm-8'>
                <div className="form-group row m-b-0">
                  <label className="col-sm-3 col-form-label">Runsheet Num</label>
                  <div className='col-sm-8'>
                    <div className="form-inline">
                      <div className="form-group m-r-10">
                        <input type="text" className="form-control form-control-sm" name="runsheetNum" value={this.state.runsheetNum ?? ""} onChange={this._handleOnChangeInputText} onKeyDown={this._handleKeyDown} disabled={this.state.uuid}/>
                      </div>
                      <div className="form-group m-r-10">
                        <label className="form-check-label">Date</label>
                        <input type="date" className="form-control form-control-sm ml-2" name="runsheetDate" value={this.state.runsheetDate ?? ""} disabled={true} />
                      </div>
                      <div className="form-group m-r-10">
                        <label className="form-check-label">Status</label>
                        <input type="text" className="form-control form-control-sm ml-2" name="runsheetStatus" value={this.state.runsheetStatus?? ""} disabled={true} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group row m-b-3">
                  <label className="col-sm-3 col-form-label">Runner</label>
                  <div className="col-sm-4">
                    <input type="text" name="runnerName" value={this.state.runnerName ?? ""} onChange={this._handleOnChangeInputText} placeholder="Runner Name" className="form-control form-control-sm mt-1 pt-1" maxLength={50} disabled={true} />
                  </div>
                  <div className="col-sm-4">
                    <input type="text" name="runnerPhone" value={this.state.runnerPhone ?? ""} onChange={this._handleOnChangeInputText} placeholder="Runner Phone" className="form-control form-control-sm mt-1 pt-1" maxLength={20} disabled={true} />
                  </div>
                </div>

                <div className="form-group row m-b-3">
                  <label className="col-sm-3 col-form-label">Remarks</label>
                  <div className="col-sm-8">
                    <textarea
                      onChange={this._handleOnChangeInputText} value={this.state.remarks ?? ""}
                      className='form-control form-control-sm' placeholder="Remarks / Note" disabled={true}></textarea>
                  </div>
                </div>
              </div>

            </div>
          </PanelBody>
        </Panel>
        {(this.state.runsheetDetails.length > 0) ?
          <Panel>
            <PanelBody>
              <div className='row'>
                <div className='col-sm-8'>
                  <div className="table-responsive table-striped bg-white custom-table-form">
                    <table className="table custom-table-form">
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>Shipment</th>
                          <th>Reason</th>
                          <th>Receiver Name</th>
                          <th>Relation</th>
                          <th>Receiver Date</th>
                          <th>Note</th>
                        </tr>

                      </thead>
                      <tbody> 
                        {this.state.runsheetDetails.map((val, key) => {
                          return (
                            <tr key={val.rdid}>
                              <td>{(key + 1)}.</td>
                              <td>{val.shipment_num}</td>
                              <td> <select className='form-control form-control-sm tbl-form' value={val.reasonCode ?? ""} name="reasonCode" onChange={this._handleTableOnChange(val.rdid)} onBlur={this._handleTableOnChange(val.rdid)}>
                                <option value=""> - </option>
                                {this.state.reasonCodes.map((item) => {
                                  return (<option value={item.code} key={item.code}>{item.code} - {item.descr}</option>)
                                })}
                              </select>
                              </td>
                              <td><input type="text" className='form-control form-control-sm' name="receiverName" value={val.receiverName ?? ""} onChange={this._handleTableOnChange(val.rdid)} disabled={val.reasonCode !== "R01"}/></td>
                              <td><select className='form-control form-control-sm' name="relation" value={val.relation ?? ""} onChange={this._handleTableOnChange(val.rdid)} disabled={val.reasonCode !== "R01"}>
                                <option value=""> - </option>
                                {this.state.relations.map((item) => {
                                  return (<option value={item} key={item}>{item}</option>)
                                })}
                              </select></td>
                              <td>
                                <div className="form-inline"><input type="date" className='form-control form-control-sm' style={{ width: "120px" }} name="receivedDate" value={val.receivedDate ?? ""} onChange={this._handleTableOnChange(val.rdid)} disabled={val.reasonCode !== "R01"}/>
                                  <input type="time" className='form-control form-control-sm ml-2' style={{ width: "90px" }} name="receivedTime" value={val.receivedTime ?? ""} onChange={this._handleTableOnChange(val.rdid)} disabled={val.reasonCode !== "R01"}/>
                                </div>
                              </td>
                              <td><input type="text" className='form-control form-control-sm' name="notes" value={val.notes ?? ""} onChange={this._handleTableOnChange(val.rdid)} /></td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </PanelBody>
          </Panel> : null}
        <NotificationContainer />
      </div>
    )
  }
}


export default withParams(RunSheetPOD); 